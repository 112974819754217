@charset "UTF-8";
@import '@craftcms/sass/mixins';

@mixin striped-bg($size, $color: $grey100) {
  // h/t https://css-tricks.com/stripes-css/
  $halfSize: $size * 0.5;
  body.ltr & {
    background: repeating-linear-gradient(
      135deg,
      var(--white),
      var(--white) $halfSize,
      $color $halfSize,
      $color $size
    );
  }
  body.rtl & {
    background: repeating-linear-gradient(
      45deg,
      var(--white),
      var(--white) $halfSize,
      $color $halfSize,
      $color $size
    );
  }
}

@font-face {
  font-family: 'Craft';
  src: url('../fonts/Craft.woff2') format('woff2'),
    url('../fonts/Craft.woff') format('woff'),
    url('../fonts/Craft.ttf') format('truetype'),
    url('../fonts/Craft.svg#Craft') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* ----------------------------------------
/*  Basic stuff
/* ----------------------------------------*/

:root {
  --ui-control-color: var(--gray-550);
  --ui-control-hover-color: var(--gray-600);
  --ui-control-active-color: var(--gray-700);
  --ui-control-bg-color: #{transparentize($inputColor, 0.75)};
  --ui-control-hover-bg-color: #{transparentize($inputColor, 0.7)};
  --ui-control-active-bg-color: #{transparentize($inputColor, 0.5)};
  --ui-control-border-radius: var(--large-border-radius);
  --ui-control-height: calc((34rem / 16)); // 1rem == 16px
  --icon-opacity: 0.8;
  --light-focus-hsl: #{hue($lightFocusColor)}, #{saturation($lightFocusColor)},
    #{lightness($lightFocusColor)};
  --medium-focus-hsl: #{hue($mediumFocusColor)},
    #{saturation($mediumFocusColor)}, #{lightness($mediumFocusColor)};
  --dark-focus-hsl: #{hue($darkFocusColor)}, #{saturation($darkFocusColor)},
    #{lightness($darkFocusColor)};
  --focus-ring: 0 0 0 1px hsl(var(--dark-focus-hsl)),
    0 0 0 3px hsla(var(--dark-focus-hsl), 0.8);
  --inner-focus-ring: inset 0 0 0 1px hsl(var(--dark-focus-hsl)),
    inset 0 0 0 3px hsla(var(--dark-focus-hsl), 0.7);
  --touch-target-size: 24px; /* Minimum recommended touch target size */
  --elements-busy-top-position: calc(50% - var(--size, 20px) / 2);
}

body,
html {
  box-shadow: var(--light-focus-ring);
  background-color: var(--gray-100);
}

html.noscroll,
html.noscroll body {
  overflow: hidden;
}

body {
  width: 100vw;
  overflow-x: hidden;
  @include fontSize(14);
  line-height: 1.42;
  color: var(--text-color);
  -webkit-font-smoothing: subpixel-antialiased;
}

body.rtl {
  direction: rtl;
}

body,
input,
select,
textarea {
  @include sans-serif-font;
}

.first,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child,
blockquote:first-child,
hr:first-child,
.pane:first-child,
.grid:first-child,
fieldset:first-child,
.field:first-child,
.toolbar:first-child,
.buttons:first-child,
.condition-container:first-child {
  margin-top: 0 !important;
}

.last,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
blockquote:last-child,
.pane:last-child,
.grid:last-child,
.meta:last-child,
fieldset:last-child,
.field:last-child,
.toolbar:last-child,
.buttons:last-child,
.condition-container:last-child {
  margin-bottom: 0 !important;
}

.no-scroll {
  overflow: hidden !important;
}

.draghelper {
  box-sizing: border-box;
}

.offset-drag-helper {
  transition: margin linear 200ms, padding linear 200ms;
}

body.dragging.ltr .offset-drag-helper {
  margin: -50px 0 0 -50px;
  padding: 60px 0 0 60px;
}

body.dragging.rtl .offset-drag-helper {
  margin: -50px -50px 0 0;
  padding: 60px 60px 0 0;
}

img {
  max-width: 100%;
}

.text,
table.editable textarea {
  body.rtl .ltr & {
    text-align: left !important;
    direction: ltr !important;
  }

  body.ltr .rtl & {
    text-align: right !important;
    direction: rtl !important;
  }
}

/* icons */
.icon:before,
.menu ul.padded li a.sel:before,
.menu .flex.padded.sel:before,
.texticon:before,
.element:before,
#help:before,
.secure:before,
.insecure:before,
.go:after,
.required:after,
.preview-btn:before,
.view-btn:before,
[data-icon]:before,
[data-icon-after]:after {
  @include icon;
}

.badge-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  box-sizing: border-box;
  border: 1px solid var(--indicator-border-color);
  border-radius: var(--small-border-radius);
  color: var(--indicator-icon-color);
  font-size: 9px;
}

.secure:before,
.insecure:before {
  margin-top: -3px;
  font-size: 14px;
}

[data-icon]:before {
  content: attr(data-icon);
}

[data-icon-after]:after {
  content: attr(data-icon-after);
}

body.rtl [data-icon='list']:before,
body.rtl [data-icon-after='list']:after {
  content: 'listrtl';
}

body.rtl [data-icon='structure']:before,
body.rtl [data-icon-after='structure']:after {
  content: 'structurertl';
}

.icon.secure:before {
  content: 'secure';
}

.icon.insecure:before {
  content: 'insecure';
}

.icon.add:before {
  content: 'plus';
}

.icon.edit:before {
  content: 'edit';
}

.icon.settings:before {
  content: 'settings';
}

.icon.search:before {
  content: 'search';
}

.icon.expand:before {
  content: 'expand';
}

.icon.collapse:before {
  content: 'collapse';
}

.help:before {
  content: 'help';
  color: var(--pink-400);
}

.preview-btn,
.view-btn {
  &:before {
    @include margin-right(var(--xs));
  }
}

.preview-btn:before {
  margin-top: -2px;
  content: 'view';
}

.view-btn:before {
  body.ltr & {
    content: 'share';
  }
  body.rtl & {
    content: 'shareleft';
  }
}

/* headings */
h1,
.h1 {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

h2,
.h2 {
  margin: 14px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
}

h3,
.h3 {
  margin: 14px 0;
  font-weight: bold;
  line-height: 1.2;
}

h4,
.h4 {
  margin: 14px 0;
  font-weight: bold;
  line-height: 1.2;
  color: var(--medium-text-color);
}

h5,
.h5 {
  margin: 14px 0 3px;
  line-height: 1.2;
  color: var(--medium-text-color);
}

h6,
.h6 {
  @include h6-styles;
}

h1[data-icon]:before {
  @include margin(-8px, 10px, 0, 0);
}

h2[data-icon]:before {
  @include margin(-4px, 6px, 0, 0);
  font-size: 19px;
}

/* horizontal rule */
hr {
  margin: 24px 0;
  border: none;
  border-top: 1px solid var(--hairline-color);
  height: 0;
  color: transparent;
}

.pane hr {
  margin: 24px -24px;
}

/* paragraphs */
p {
  margin: 1em 0;
}

h5 + p {
  margin-top: 0;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

.indent {
  @include margin-left(14px);
}

/* lists */
.bullets {
  @include padding-left(40px);
  list-style-type: square;
}

ol {
  @include padding-left(40px);
  list-style-type: decimal;
}

/* code */
code,
.code {
  @include fixed-width-font;

  &.smalltext {
    font-size: 0.8em !important;
  }
}

.code {
  input,
  textarea {
    @include fixed-width-font;
    font-size: 1em !important;
  }
}

pre code {
  display: block;
  overflow-x: auto;
}

/* links */
a {
  color: var(--link-color);
  cursor: pointer;

  &[aria-current] {
    cursor: default;
  }

  body.underline-links & {
    text-decoration: underline;
  }
}

a:hover {
  text-decoration: underline;
}

a.sel,
li.sel a {
  cursor: default !important;
  text-decoration: none;
}

.go:after {
  font-size: 11px;
  margin-top: -1px;
  @include padding-left(4px);
  color: var(--link-color);
  opacity: 0.9;
  body.ltr & {
    content: 'circlerarr';
  }
  body.rtl & {
    content: 'circlelarr';
  }
}

a [data-icon='external'] {
  @include margin-left(var(--xs));
}

button {
  cursor: pointer;
}

/* revision button */
.context-btn.disabled {
  opacity: 1;
  color: var(--medium-dark-text-color);
  background-color: transparentize($grey200, 0.5) !important;
}

/* status icons */
.checkmark-icon,
.alert-icon {
  padding: 5px;
  margin-bottom: 0 !important;
  line-height: 10px;
  border-radius: 20px;
  cursor: pointer;

  &:before {
    @include icon;
  }
}

.checkmark-icon {
  p & {
    display: inline-block;
  }

  background-color: var(--gray-200);

  &:before {
    content: 'check';
    color: var(--success-color);
  }
}

.alert-icon {
  background-color: var(--gray-200);

  &:before {
    content: 'alert';
    color: var(--error-color);
  }
}

.menu.revision-menu {
  padding: 10px 24px;

  hr {
    margin: 10px -24px;
  }

  ul {
    li {
      &.sel {
        margin: 0 -14px;
        padding: 0 14px;
        background: var(--gray-050);
        border-radius: var(--large-border-radius);

        .edited-desc {
          display: flex;
          align-items: center;
          @include margin-left(10px);
          border-top: 1px solid var(--hairline-color);
          padding: 10px 0;
          color: var(--light-text-color);
          white-space: normal;

          p {
            margin: 0;
          }

          .btn {
            @include margin-left(14px);
          }
        }
      }

      a {
        padding-top: 7px;
        border-radius: var(--large-border-radius);
      }
    }
  }

  .extralight {
    margin-top: 2px;
  }
}

.revision-status-hud {
  max-width: 400px;

  .http-error {
    border-radius: var(--medium-border-radius);
    border: 1px solid var(--hairline-color);
    color: var(--light-text-color);
    background-color: var(--gray-050);
    padding: 7px 14px;
  }
}

.draft-notice {
  display: flex;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  gap: var(--s);
  color: var(--blue-800);

  #content-notice & {
    display: inline-flex;
    justify-content: flex-start;
  }

  .so-notice > & {
    @include margin(
      calc(var(--s) * -1),
      0,
      calc(var(--s) * -1),
      calc(var(--xl) * -1)
    );
    @include padding(var(--s), 0, var(--s), var(--xl));
    @include border-bottom-left-radius(var(--large-border-radius));

    body.ltr & {
      background-image: linear-gradient(
        to bottom right,
        transparentize($blue600, 0.8),
        transparentize($blue600, 1) 50%
      );
    }
    body.rtl & {
      background-image: linear-gradient(
        to left,
        transparentize($blue600, 0.8),
        transparentize($blue600, 1)
      );
    }
  }

  p {
    flex: 1;
    margin: 0;
  }

  .draft-icon {
    position: relative;
    flex-shrink: 0;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 2px solid transparentize($blue800, 0.8);
    box-sizing: border-box;
    box-shadow: 0 1px 1px 1px var(--white);

    &:before {
      position: relative;
      left: 1px;
      color: var(--blue-800) !important;
      font-size: 18px;
    }

    &:after {
      content: '';
      font-size: 0;
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: 100%;
      box-shadow: inset 0 2px 0 transparentize($blue900, 0.8);
    }
  }

  .discard-changes-btn {
    @include margin-left(var(--xs));
    background-color: transparent !important;
    color: var(--blue-800) !important;
    border: 2px solid var(--blue-600);

    &:hover,
    &:focus {
      border-color: darken($blue600, 5%);
    }

    &:active {
      border-color: darken($blue600, 10%);
    }
  }
}

.revision-notice {
  display: flex;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  gap: var(--s);
  color: var(--gray-600);

  #content-notice & {
    display: inline-flex;
    justify-content: flex-start;
  }

  p {
    flex: 1;
    margin: 0;
  }

  .revision-icon {
    position: relative;
    flex-shrink: 0;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 2px solid transparentize($grey800, 0.8);
    box-sizing: border-box;
    box-shadow: 0 1px 1px 1px var(--white);

    &:before {
      position: relative;
      left: 1px;
      color: var(--grey-800) !important;
      font-size: 18px;
    }

    &:after {
      content: '';
      font-size: 0;
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: 100%;
      box-shadow: inset 0 2px 0 transparentize($blue900, 0.8);
    }
  }
}

/* toggles */
button.toggle {
  appearance: none;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
}

.toggle:before,
a.fieldtoggle:before {
  @include angle(right);
  transition: transform linear 100ms;
}

.toggle.expanded:before,
a.fieldtoggle.expanded:before,
.sidebar nav li.expanded > .toggle:before,
.structure li:not(.collapsed) > .row > .toggle:before {
  transform: rotate(45deg) !important;
}

a.fieldtoggle {
  display: block;
  position: relative;
  margin: 14px 0;
  @include padding-left(12px);
  color: var(--text-color);
  text-decoration: none;
}

a.fieldtoggle:before {
  display: block;
  position: absolute;
  top: 7px;
  @include left(-1px);
}

/* emphasis */
em,
i {
  font-style: italic;
}
ul.errors em {
  font-style: normal;
}

strong,
b,
i em {
  font-weight: bold;
}

/* readable blocks */
.readable {
  @include readable;
}

/* text styles */
.leftalign {
  @include alignleft;
}

.topalign {
  vertical-align: top;
}

.rightalign {
  @include alignright;
}

.centeralign {
  text-align: center !important;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-wrap: break-word;
}

.light {
  color: var(--medium-text-color) !important;
  font-weight: normal;
}

.extralight {
  color: var(--light-text-color) !important;
}

.smalltext {
  font-size: 12px;
  line-height: 1.2;
}

.largetext {
  font-size: 16px;
  line-height: 1.2;
}

.zilch {
  padding: 100px 0;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: var(--light-text-color);

  &.small {
    padding: 24px 0;
    font-size: 16px;
  }
}

input.checkbox + label.smalltext {
  padding-top: 2px;
}

.required:after {
  content: 'asterisk';
  @include margin(-2px, 0, 0, 5px);
  font-size: 7px;
  color: var(--error-color);
}

.scrollpane {
  overflow: auto;
}

.left {
  @include floatleft;
}

.right {
  @include floatright;
}

th,
td {
  @include alignleft;
  vertical-align: middle;
}

body.ltr table[dir='rtl'] {
  th,
  td {
    text-align: right;
  }
}
body.rtl table[dir='ltr'] {
  th,
  td {
    text-align: left;
  }
}

th.right,
td.right {
  float: none;
  @include alignright;
}

.clear {
  display: block;
  clear: both;
  height: 0;
}

.fullwidth {
  width: 100%;
}

.token {
  @include token-styles;
}

.token[data-name='*'] {
  position: relative;
  width: 10px;
}

.token[data-name='*'] span {
  opacity: 0;
}

.token[data-name='*']:before {
  @include icon;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 9px;
  line-height: 17px;
  content: 'asterisk';
  text-indent: 0;
}

.token:focus {
  @include active-token-styles;
}

// Override .token for Prism
.highlight {
  .token {
    display: inline;
    border: none;
    border-radius: 0;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    text-shadow: none;
    background: transparent;
    box-shadow: none;
  }
}

.pane.highlight {
  pre[class*='language-'] {
    overflow: visible;

    & > code.diff-highlight .token:not(.prefix) {
      margin: 0 -24px;
      padding: 0 24px;
    }
  }
}

.success {
  color: var(--success-color) !important;
}

.notice,
.warning {
  &.with-icon,
  .icon {
    &:before {
      @include margin(-2px, 2px, 0, 0);
      @include icon;
      width: 1em;
    }
  }

  &.has-icon {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    .icon {
      flex-shrink: 1;
    }
  }
}

.notice {
  color: var(--notice-color) !important;
  a {
    text-decoration: underline;
  }

  &.with-icon,
  .icon {
    &:before {
      content: 'lightbulb';
    }
  }
}

.warning {
  color: var(--warning-color) !important;

  &.with-icon,
  .icon {
    &:before {
      content: 'alert';
    }
  }
}

.error {
  color: var(--error-color) !important;
}

.icon.move {
  display: inline-block;
}

.icon.move:not(.disabled) {
  cursor: move;
}

.icon.move:before {
  content: 'move';
  color: var(--ui-control-color);
}

.icon.move:not(.disabled):hover:before {
  color: var(--link-color);
}

.icon.delete {
  display: inline-block;
  line-height: inherit;

  &:before {
    content: 'remove';
    color: var(--ui-control-color);
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover:before {
      color: var(--disabled-color);
    }

    &:active:before {
      color: var(--red-800);
    }
  }
}

.hidden {
  display: none !important;
}

// Visually hide without hiding from screen readers
.visually-hidden {
  @include visually-hidden;
}

.invisible {
  visibility: hidden;
}

.clearafter:after {
  @include clearafter;
}

.info {
  vertical-align: bottom;
  display: inline-block;
  width: 1em;
  height: 1.375em;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  &:before {
    @include icon;
    vertical-align: baseline;
    width: 100%;
    line-height: 1.375;
    color: var(--ui-control-color);
  }

  &:not(.warning) {
    &:before {
      content: 'info';
    }
    &:hover:before {
      color: var(--link-color);
    }
  }

  &.warning {
    &:before {
      content: 'alert';
    }
    &:hover:before {
      color: var(--warning-color);
    }
  }
}

.info-hud {
  table {
    max-width: 280px;
    table-layout: auto;
  }

  td {
    word-wrap: break-word;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .info-hud {
    table {
      table-layout: fixed;
      width: 100%;
    }
  }
}

/* ----------------------------------------
/*  Content
/* ----------------------------------------*/

.content {
  position: relative;
}

.content:after {
  @include clearafter;
}

.customize-sources-modal {
  @include padding-left(200px);
  overflow: visible !important;

  & > .cs-sidebar {
    position: absolute;
    top: 0;
    @include left(0);
    margin: 0;
    padding: 10px 0;
    border: none;
    width: 200px;
    height: calc(100% - 50px);
    box-sizing: border-box;
    background-color: var(--gray-050);
    overflow: auto;
    box-shadow: inset -1px 0 0 var(--hairline-color);
    @include border-top-left-radius(var(--large-border-radius));

    & > .btn {
      @include margin(10px, 0, 0, 14px);
      display: block;
      width: calc(100% - 28px);
    }
  }

  &.sidebar-hidden {
    @include padding-left(0);

    & > .cs-sidebar {
      display: none;
    }
  }

  & > .source-settings {
    position: relative;
    height: calc(100% - 50px);
    box-sizing: border-box;
    padding: 24px;
    overflow: auto;
  }

  & > .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.customize-sources-item {
  --selected-item-color: var(--white);
  display: flex;
  width: calc(100% - 1px);
  box-sizing: border-box;
  align-items: center;
  position: relative;
  margin-top: -1px;
  background-color: var(--gray-100);
  border: solid var(--hairline-color);
  border-width: 1px 0;
  user-select: none;
  cursor: default;

  & + .customize-sources-item {
    &.heading {
      margin-top: 10px;
    }

    &:not(.heading) {
      border-top: 1px solid var(--hairline-color);
    }
  }

  &.sel {
    @include light-focus-ring;
    --ui-control-color: var(--selected-item-color);
    background-color: var(--dark-sel-color);
    color: var(--selected-item-color);
    z-index: 1;
  }

  .label {
    flex: 1;
  }

  &.heading {
    .label {
      text-transform: uppercase;
      color: var(--light-text-color);
      font-size: 12px;
      font-weight: bold;
    }

    &.sel .label {
      color: var(--selected-item-color);
    }
  }
}

.customize-sources-item__move {
  position: absolute;
  top: 50%;
  @include right(0);
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;

  &.icon {
    display: flex;
    text-decoration: none;
  }
}

.customize-sources-item__btn {
  padding: 8px 14px;
  @include padding-right(30px);
  margin: 3px;
}

.customize-sources-table-column {
  display: flex;
  align-items: flex-start;
  align-content: stretch;
  margin-bottom: 4px;

  .move {
    margin-top: -3px;
    @include margin-right(10px);
    position: relative;
    z-index: 1;
  }
}

ul.path {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;

  li {
    display: inline-flex;
    align-items: center;

    &:not(:last-child):after {
      @include margin(0, 5px, 0, 2px);
      @include angle(right, var(--light-text-color), 1px);
    }
  }
}

/* ----------------------------------------
/*  Icon lists
/* ----------------------------------------*/

ul.icons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 0 4px 10px 0;

    a {
      display: block;
      position: relative;
      padding: 60px 5px 10px;
      width: 110px;
      text-align: center;
      color: var(--text-color);
      border-radius: 4px;
      border: 1px solid var(--white);

      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 40px;
        line-height: 60px;
      }

      .icon {
        img,
        svg {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 12px;
          left: calc(50% - 20px);
        }

        &.icon-mask svg {
          @include svg-mask(var(--text-color));
        }
      }

      &:hover {
        text-decoration: none;
        background-color: var(--gray-050);
        border-color: var(--gray-100);

        .icon.icon-mask svg {
          @include svg-mask(var(--link-color));
        }
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  ul.icons li a {
    width: 96px;
  }
}

@media only screen and (max-width: 320px) {
  ul.icons li a {
    width: 75px;
  }
}

/* ----------------------------------------
/*  Buttons
/* ----------------------------------------*/

.toolbar {
  position: relative;
  margin-bottom: 14px;
  min-height: 34px;

  &.flex,
  .flex {
    align-items: flex-start;
  }

  .text {
    border-radius: var(--large-border-radius) !important;
  }
}

.flex {
  display: flex;
  align-items: center;
  align-content: stretch;
  gap: var(--s);

  &:not(.flex-nowrap) {
    flex-wrap: wrap;
  }

  & > * {
    &.label {
      white-space: nowrap;
    }
  }

  .centeralign & {
    justify-content: center;
  }
}

.inline-flex {
  display: inline-flex;
  align-items: center;
  align-content: stretch;
  gap: var(--s);
}

.gap-xs {
  gap: var(--xs);
}

.gap-s {
  gap: var(--s);
}

.gap-m {
  gap: var(--m);
}

.gap-l {
  gap: var(--l);
}

.gap-xl {
  gap: var(--xl);
}

.flex-grow {
  flex: 1;
  max-width: 100%;
}

.flex-justify {
  justify-content: space-between;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-start {
  align-items: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.flex-center {
  align-items: center;
}

.flex-stretch {
  align-items: stretch;
}

.spacer {
  width: 14px;
}

.buttons {
  display: flex;
  gap: 7px;
  position: relative;
  margin: 24px 0;
  align-items: center;

  .hud-footer > &,
  .footer > & {
    margin: 0;
  }
}

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--ui-control-border-radius);
  padding: 7px 14px;
  border: none;
  text-align: center;
  user-select: none;
  box-sizing: border-box;
  appearance: none;
  color: currentColor;
  font-size: inherit;
  background-color: var(--ui-control-bg-color);

  &:not([aria-current]) {
    cursor: pointer;
  }

  &.chromeless {
    background-color: transparent;
    height: auto;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  &.hairline,
  &.link-btn {
    border: 1px solid var(--medium-hairline-color);
    background-color: transparent;

    &:active,
    &[aria-expanded='true'] {
      background-color: var(--ui-control-static-bg-color);
    }
  }

  &.hairline {
    &:hover,
    &:active,
    &[aria-expanded='true'] {
      border-color: var(--dark-hairline-color);
    }
  }

  &.link-btn {
    color: var(--link-color);

    &:hover,
    &:active,
    &[aria-expanded='true'] {
      border-color: var(--link-color);
    }
  }

  &.wrap {
    height: auto;
    min-height: 34px;
    white-space: initial;
    text-align: left;
  }

  &:not(.disabled):not(.loading):not(.dashed):not([aria-disabled]):not(
      [aria-current]
    ) {
    &:focus,
    &.focus,
    &:hover {
      --ui-control-bg-color: var(--ui-control-hover-bg-color);
    }

    &:active,
    &.active {
      --ui-control-bg-color: var(--ui-control-active-bg-color);
    }
  }

  &[type='color'] {
    padding: 6px !important;
    width: 36px;
  }

  &.active,
  &.loading {
    cursor: default;
  }

  &:hover {
    text-decoration: none;
  }

  &[data-icon]:not(:empty):not(.btn-empty):before,
  &.icon:not(:empty):not(.btn-empty):before,
  &.menubtn[data-icon]:empty:before,
  &.menubtn[data-icon].btn-empty:before,
  &.menubtn.icon:empty:before,
  &.menubtn.icon.btn-empty:before {
    @include margin-right(5px);
  }

  &:not(.loading) .spinner {
    display: none;
  }

  &.loading {
    &:before,
    &:after,
    .label {
      visibility: hidden;
    }
  }

  div.checkbox {
    margin-top: 2px;
  }

  [data-icon] {
    margin-top: -2px;
    @include margin-right(4px);

    &.light:before {
      color: var(--gray-300);
    }
  }
}

.disabled {
  opacity: 0.25;
  pointer-events: none;
  user-select: none;
}

.noteditable {
  cursor: not-allowed;

  .element,
  .lightswitch-container,
  & + .colorhex {
    cursor: not-allowed;
  }
}

.btn {
  min-height: var(--ui-control-height);
}
.spinner {
  height: var(--ui-control-height);
}

.btn[data-icon-after]:not(:empty):not(.btn-empty):after,
.menu-toggle:not(:empty):not(.btn-empty):after,
.menubtn:not(:empty):not(.btn-empty):after,
.menubtn.icon:after {
  @include margin-left(6px);
}

.btn[data-icon]:before,
.btn[data-icon-after]:after,
.btn.icon:before {
  position: relative;
}

.btn.small[data-icon]:before,
.btn.small[data-icon-after]:after,
.btn.icon.small:before {
  font-size: 10px;
}

/* button groups */
.btngroup {
  position: relative;
  z-index: 1;
  display: flex;
  white-space: nowrap;
  align-items: center;
  border-radius: var(--large-border-radius);

  &.fullwidth .btn {
    flex: 1;
  }

  &.disabled .btn {
    cursor: default;
  }

  .btn {
    &:focus {
      z-index: 1;
    }

    &:not(.dashed):not(:last-child):not(.btngroup-btn-last) {
      @include margin-right(1px);
    }

    body.ltr & {
      &:not(:first-child):not(.btngroup-btn-first) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:last-child):not(.btngroup-btn-last) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    body.rtl & {
      &:not(:first-child):not(.btngroup-btn-first) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:not(:last-child):not(.btngroup-btn-last) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.btngroup--exclusive {
  .btn[aria-pressed='true']:not(.disabled):not(.loading):not(.dashed):not(
      [aria-disabled]
    ) {
    background-color: var(--gray-500);
    color: var(--white);
  }
}

.copytext {
  position: relative;
  z-index: 1;
  display: flex;
  white-space: nowrap;
  align-items: center;

  body.ltr & {
    .text {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      min-width: 0;
    }
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  body.rtl & {
    .text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.copytextbtn {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid var(--hairline-color);
  border-radius: var(--small-border-radius);
  padding: 0 9px;
  cursor: pointer;
  color: var(--medium-text-color);

  .copytextbtn__icon {
    padding: 0;
    width: 13px;
    background: none;
    margin-top: -3px;
    @include margin-left(5px);
    color: var(--light-text-color);
  }

  &:hover,
  &:focus {
    border-color: var(--medium-hairline-color);
    color: var(--text-color);

    .copytextbtn__value {
      color: var(--text-color);
    }
  }

  &.small {
    padding: 0 5px;

    .copytextbtn__value {
      font-size: calc(11rem / 16);
    }
  }
}

/* menu buttons */
.menu-toggle,
.menubtn {
  display: inline-flex;
  align-items: center;
  user-select: none;

  &:after {
    @include angle;
    position: relative;
  }

  &.btn {
    &:after {
      top: -1px;
    }

    &:not(.disabled):not(.inactive) {
      &:active,
      &.active {
        &:after {
          border-color: var(--ui-control-active-color);
        }
      }
    }

    &.submit {
      &:after {
        border-color: var(--white) !important;
        opacity: 0.8;
      }

      &:not(.disabled):not(.inactive) {
        &:hover,
        &.hover,
        &:active,
        &.active {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  &:not(.btn):not(.icon) {
    height: 17px;
    &:after {
      top: -2px;
      border-color: var(--link-color);
    }
  }

  &:empty,
  &.btn-empty {
    @include padding-left(8px);
    @include padding-right(8px);
  }
}

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

/* spinner */
.spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size, 24px);
  height: var(--size, 34px);

  &:before {
    display: block;
    content: '';
    font-size: 0;
    animation: rotator 0.7s linear infinite;
    box-sizing: border-box;
    width: var(--size, 20px);
    height: var(--size, 20px);
    object-fit: scale-down;
    border-radius: 50%;
    border: 2px solid transparent;
    border-right-color: currentColor;
    border-bottom-color: currentColor;
    opacity: 0.8;
  }

  &.small {
    --size: 12px;
  }

  &.big {
    --size: 48px;
  }

  &.spinner-absolute {
    position: absolute;
    width: var(--size, 20px);
    height: var(--size, 20px);
    top: var(--elements-busy-top-position);
    left: calc(50% - var(--size, 20px) / 2);
  }
}

.btn + .spinner {
  @include margin-left(7px);
}

.buttons .btn + .spinner,
.buttons .btngroup + .spinner {
  @include margin-left(0);
}

.buttons.right .btn + .spinner {
  @include margin-right(var(--neg-padding));
}

/* small buttons */
.btngroup.small .btn,
.btn.small {
  padding: 0 7px !important;
  font-size: 12px;
  line-height: 22px;
}

.btngroup.small,
.btngroup.small input.btn,
.btn.small,
.btn.small + .spinner {
  min-height: 22px;
}

/* big buttons */
.btngroup.big .btn,
.btn.big {
  padding: 0 14px;
  font-size: 14px;
  line-height: 36px;
}

.btn.big[data-icon]:before,
.preview-btn:before,
.view-btn:before {
  @include margin-left(-2px);
}

.btngroup.big,
.btngroup.big input.btn,
.btn.big,
.btn.big + .spinner {
  min-height: 36px;
}

/* special buttons */
.btn {
  &.submit,
  &.secondary {
    color: var(--white) !important;
    --ui-control-active-color: var(--white);
    @include light-on-dark-text;
  }

  &.submit {
    background-color: var(--primary-color) !important;

    &:not(.disabled):not(.inactive):not(.loading) {
      &:hover,
      &.hover,
      &:focus {
        background-color: darken($primaryColor, 5%) !important;
      }

      &:active,
      &.active {
        background-color: darken($primaryColor, 10%) !important;
      }
    }
  }

  &.caution {
    background-color: var(--red-050) !important;
    color: var(--error-color);

    &:hover,
    &.hover,
    &:focus {
      background-color: darken($red050, 5%) !important;
    }

    &:active,
    &.active {
      background-color: var(--red-100) !important;
    }
  }
}

.secondary-buttons .btn.submit,
.btn.secondary {
  background-color: var(--secondary-color) !important;
}

.secondary-buttons
  .btn.submit:not(.disabled):not(.inactive):not(.loading):hover,
.secondary-buttons
  .btn.submit:not(.disabled):not(.inactive):not(.loading).hover,
.secondary-buttons
  .btn.submit:not(.disabled):not(.inactive):not(.loading):focus,
.btn.secondary:not(.disabled):not(.inactive):not(.loading):hover,
.btn.secondary:not(.disabled):not(.inactive):not(.loading).hover,
.btn.secondary:not(.disabled):not(.inactive):not(.loading):focus {
  background-color: darken($secondaryColor, 5%) !important;
}

.secondary-buttons
  .btn.submit:not(.disabled):not(.inactive):not(.loading):active,
.secondary-buttons
  .btn.submit:not(.disabled):not(.inactive):not(.loading).active,
.btn.secondary:not(.disabled):not(.inactive):not(.loading):active,
.btn.secondary:not(.disabled):not(.inactive):not(.loading).active {
  background-color: darken($secondaryColor, 10%) !important;
}

div.btn.submit {
  position: relative;
  overflow: hidden;
}

div.btn.submit input {
  position: absolute;
  left: 100%;
}

/* dashed buttons */
.btn.dashed {
  border: 1px dashed var(--medium-hairline-color);
  background-color: transparent;

  .btngroup &:not(:last-child):not(.btngroup-btn-last) {
    @include border-right(1px solid transparent);
    @include margin-right(-1px);
  }

  &:focus {
    background-color: transparentize($grey200, 0.9);
    border-color: transparent;

    .reduce-focus-visibility &:not(:focus-visible) {
      border: 1px dashed var(--medium-hairline-color);
    }
  }

  &:not(.disabled) {
    &:active,
    &.active {
      background-color: transparentize($grey200, 0.75);
    }
  }
}

/* chevron buttons */
.chevron-btns {
  display: flex;
  justify-content: flex-start;

  .btn {
    position: relative;
    padding: 0;
    border-radius: 0;
    background-color: transparent;

    &.current-step,
    &.active-drop-target {
      color: var(--white);
      --text-color: var(--white);
      --ui-control-color: var(--white);
      --ui-control-bg-color: var(--gray-500);
      --ui-control-hover-bg-color: #{darken($grey500, 5%)};
      --ui-control-active-bg-color: #{darken($grey500, 10%)};
    }

    &.current-step {
      @include light-on-dark-text;
      @include light-focus-ring;
    }

    &:focus {
      z-index: 1;
      box-shadow: none;

      .label {
        z-index: 1;
        box-shadow: var(--focus-ring);
      }

      .reduce-focus-visibility &:not(:focus-visible) .label {
        box-shadow: none;
      }
    }

    .reduce-focus-visibility &:focus-visible .label {
      box-shadow: var(--focus-ring);
    }

    &:not(.has-action-menu) {
      @include padding-right(calc(var(--ui-control-height) / 2));
      @include margin-right(2.8px);

      &:not(.current-step[data-disclosure-trigger]) .btn-body {
        @include padding-right(5px);
      }
    }

    &.current-step[data-disclosure-trigger] .btn-body {
      @include padding-left(8px);

      .label {
        padding: 3px;

        &:after {
          @include angle;
          position: relative;
        }
      }
    }

    .btn-body {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: var(--ui-control-height);
      background-color: var(--ui-control-bg-color);

      [data-icon] {
        --ui-control-height: calc((13rem / 16)); // 1rem == 16px
        text-align: center;
        @include margin-right(0);
      }
    }

    .label {
      display: inline-flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.has-action-menu .btn-body {
      @include padding-right(1px);
      @include margin-right(0);
    }

    .chevron-right,
    .chevron-left {
      position: absolute;
      top: 0;
      height: 100%;
      width: calc(var(--ui-control-height) / 2);
    }

    .chevron-left {
      @include left(0);
      border-top: calc(var(--ui-control-height) / 2) solid
        var(--ui-control-bg-color);
      @include border-left(
        calc(var(--ui-control-height) / 2) solid transparent
      );

      &:after {
        display: block;
        content: '';
        font-size: 0;
        position: absolute;
        bottom: 0;
        @include right(0);
        border-bottom: calc(var(--ui-control-height) / 2) solid
          var(--ui-control-bg-color);
        @include border-left(
          calc(var(--ui-control-height) / 2) solid transparent
        );
      }
    }

    .chevron-right {
      @include right(0);
      border-top: calc(var(--ui-control-height) / 2) solid transparent;
      border-bottom: calc(var(--ui-control-height) / 2) solid transparent;
      @include border-left(
        calc(var(--ui-control-height) / 2) solid var(--ui-control-bg-color)
      );
    }
  }

  ol {
    position: relative;
    @include padding-left(0);
    list-style-type: none;
    display: flex;

    li {
      &.first-step .btn {
        .btn-body {
          @include padding-left(14px);
          @include border-radius(
            var(--ui-control-border-radius),
            0,
            0,
            var(--ui-control-border-radius)
          );
        }

        .chevron-left {
          display: none;
        }
      }

      &:not(.first-step) .btn {
        @include margin-left(calc(-1 * (var(--ui-control-height) / 2)));
        @include padding-left(calc(var(--ui-control-height) / 2));

        .btn-body {
          @include padding-left(7px);
        }
      }
    }
  }
}

/* color inputs */
.color-input-container {
  position: relative;

  .color-hex-indicator {
    position: absolute;
    top: 0;
    @include left(7px);
    width: 1em;
    line-height: 34px;
    text-align: center;
    user-select: none;
  }
}

.color-input {
  @include fixed-width-font;
  @include padding-left(calc(7px + 1em));
}

.color {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  padding: 0;

  &:not(.static):not(.noteditable) {
    cursor: pointer;
  }

  &:not(.small) {
    @include checkered-bg(17px);
  }

  &.small {
    width: 16px;
    height: 16px;
    @include checkered-bg(8px);
  }

  .color-preview {
    position: absolute;
    top: 0;
    @include left(0);
    width: 100%;
    height: 100%;
    border-radius: 17px;
    box-shadow: inset 0 0 0 1px transparentize($black, 0.85);

    &:focus-within {
      @include input-focused-styles;
    }

    & > .color-preview-input {
      position: absolute;
      @include left(0);
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: none;
      opacity: 0;
    }
  }
}

.colorhex {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  color: var(--medium-text-color);
}

/* lightswitch */
.lightswitch-outer-container {
  display: flex;

  .lightswitch-inner-container {
    border: 1px solid var(--hairline-color);
    border-radius: var(--small-border-radius);
    display: flex;
    align-items: center;
    max-width: 100%;
    padding-left: 7px;
    padding-right: 7px;

    span {
      flex-grow: 1;
      padding: 7px 0;
      color: var(--medium-text-color);
      cursor: default;
      overflow: auto;
      overflow-wrap: break-word;
      hyphens: auto;

      &[data-toggle='on'] {
        @include margin-left(7px);
      }

      &[data-toggle='off'] {
        @include margin-right(7px);
        text-align: right;

        body.rtl & {
          text-align: left;
        }
      }
    }
  }
}

.lightswitch {
  display: block;
  position: relative;
  border: none !important;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  background-image: linear-gradient(to right, var(--gray-400), var(--gray-400));
  transition: background-image linear 100ms;

  &.on {
    background-image: linear-gradient(
      to right,
      var(--enabled-color),
      var(--enabled-color)
    );
  }

  &.indeterminate {
    background-image: linear-gradient(
      to right,
      var(--enabled-color),
      var(--gray-300)
    );
  }

  .lightswitch-container {
    position: relative;
    height: 100%;

    .handle {
      position: absolute;
      top: 1px;
      background-color: var(--white);
    }
  }

  &:not(.small) {
    border-radius: 11px;
    min-width: 34px;
    width: 34px;
    height: 22px;

    .lightswitch-container {
      @include margin-left(-12px);
      width: 46px;

      .handle {
        border-radius: 10px;
        width: 20px;
        height: 20px;
        left: calc(50% - 10px);
      }
    }
  }

  &.small {
    border-radius: 9px;
    width: 28px;
    height: 18px;

    .lightswitch-container {
      @include margin-left(-10px);
      width: 38px;

      .handle {
        border-radius: 8px;
        width: 16px;
        height: 16px;
        left: calc(50% - 8px);
      }
    }
  }

  table & {
    display: inline-block;
    margin-bottom: -5px;
  }

  &.on {
    .lightswitch-container {
      @include margin-left(0);
    }
  }

  &.indeterminate {
    &:not(.small) {
      .lightswitch-container {
        @include margin-left(0.5 * -12px);
      }
    }
    &.small {
      .lightswitch-container {
        @include margin-left(0.5 * -10px);
      }
    }
  }
}

/* pagination */
.pagination {
  table.data + & {
    margin-top: 24px;
  }

  .page-link {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--medium-border-radius);

    &:after {
      position: relative;
      transition: border-color linear 100ms;
    }

    &.prev-page:after {
      @include angle(left, var(--light-text-color));
      @include right(-1px);
    }

    &.next-page:after {
      @include angle(right, var(--light-text-color));
      @include left(-1px);
    }

    &:not(.disabled) {
      transition: box-shadow linear 100ms;
      box-shadow: inset 0 0 0 1px var(--hairline-color);
      cursor: pointer;

      &:hover {
        text-decoration: none;
        box-shadow: inset 0 0 0 1px var(--link-color);

        &:after {
          border-color: var(--link-color);
        }
      }

      @include focus-styles {
        box-shadow: inset 0 0 0 1px var(--hairline-color), var(--focus-ring);
      }
    }

    &.disabled {
      opacity: 1;
      &:after {
        border-color: var(--hairline-color);
      }
    }
  }
}

/* action buttons */
.actions {
  @include floatright;
}

.actions > li {
  @include floatleft;
}

.actions > li + li {
  @include margin-left(10px);
}

h1 + .actions {
  margin-top: -100px;
}

h2 + .actions {
  margin-top: -54px;
}

/* ----------------------------------------
/*  Tables
/* ----------------------------------------*/

.tablepane {
  margin: -22px -22px -10px;
  overflow-x: auto;

  table.data {
    th,
    td {
      &:first-child {
        @include padding-left(24px);
      }
      &:last-child {
        @include padding-right(24px);
      }
    }
  }
}

table {
  &.fixed-layout {
    table-layout: fixed;
  }

  th.thin,
  td.thin {
    width: 0.01% !important;
    white-space: nowrap;
  }

  thead {
    th {
      font-weight: bold;
      @include alignleft;
      vertical-align: top;
    }
  }

  // plain tables
  &:not(.data) {
    th,
    td {
      padding-top: 7px;
      padding-bottom: 7px;

      &:not(:first-child) {
        padding-left: 12px;
      }
      &:not(:last-child) {
        padding-right: 12px;
      }
    }
  }

  // data tables
  &.data {
    th,
    td {
      position: relative;
      padding-left: 12px;
      padding-right: 12px;
      box-sizing: border-box;

      &.checkbox-cell {
        width: var(--checkbox-size) !important;
        min-width: var(--checkbox-size);
        box-sizing: content-box;
        position: relative;

        input.checkbox + label,
        div.checkbox {
          position: absolute;
          top: calc(50% - 8px);
        }
      }
    }

    th {
      font-weight: bold;
    }

    thead:first-child,
    tbody:first-child,
    tfoot:first-child,
    caption + thead,
    caption + tbody,
    caption + tfoot {
      tr:first-child {
        th,
        td {
          &:first-child {
            @include border-top-left-radius(var(--small-border-radius));
            @include border-bottom-left-radius(var(--small-border-radius));
          }
          &:last-child {
            @include border-top-right-radius(var(--small-border-radius));
            @include border-bottom-right-radius(var(--small-border-radius));
          }
        }
      }
    }

    thead {
      th,
      td {
        width: auto;
        background-color: var(--gray-050);
        cursor: default;
      }

      th:not(.orderable),
      td {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      th {
        white-space: nowrap;
        vertical-align: middle;

        &.orderable {
          position: relative;
          padding-left: 0;
          padding-right: 0;

          body:not(.dragging) &:not(.ordered):hover {
            background-color: var(--gray-100);
          }

          &.ordered {
            background-color: var(--light-sel-color);

            &:not(.loading) {
              button:after {
                @include angle(up);
                position: absolute;
                @include right(10px);
                top: calc(50% - 3px);
              }

              &.desc button:after {
                transform: rotate(45deg);
              }
            }
          }

          button {
            position: relative;
            @include padding-right(26px);
            font: inherit;
            width: 100%;
            padding: 14px;
            @include alignleft;

            &:focus {
              z-index: 1;
            }
          }
        }

        &:not(.loading) {
          .spinner {
            display: none;
          }
        }

        .spinner {
          position: absolute;
          top: calc(50% - 6px);
          @include right(8px);
          --size: 12px;
        }
      }
    }

    tbody {
      tr {
        --hover-bg-color: var(--gray-050);
        --selected-bg-color: var(--dark-sel-color);

        &:not(.disabled) {
          &:hover {
            th,
            td {
              background-color: var(--hover-bg-color);
            }
          }

          &:focus {
            position: relative;
            z-index: 1;
          }

          &.sel,
          &.active-drop-target {
            --text-color: var(--white);
            --medium-dark-text-color: var(--white);
            --medium-text-color: var(--white);
            --light-text-color: var(--white);
            --link-color: var(--white);
            --ui-control-color: var(--white);
            @include custom-color-focus-ring(hsl(var(--light-focus-hsl)));

            th,
            td {
              color: var(--white);
              background-color: var(--selected-bg-color);
            }
          }
        }
      }

      th,
      td {
        padding-top: 7px;
        padding-bottom: 7px;
      }

      td {
        &.timestamp {
          @include alignright;
          vertical-align: bottom;
          white-space: nowrap;
          color: var(--light-text-color);
        }
      }
    }

    thead + tbody tr,
    tr + tr {
      th,
      td {
        border-top: 1px solid transparent;
      }
    }

    tr.sel:not(.draggee) + tr.sel:not(.draggee) {
      th,
      td {
        border-top-color: var(--gray-300);
      }
    }

    &.vuetable {
      th.sortable {
        &:hover {
          color: var(--text-color);
          background-color: var(--gray-100);
        }

        &.ordered {
          background-color: var(--light-sel-color);

          .sort-icon {
            @include angle(up);
            margin-top: 7px;
          }

          &.desc .sort-icon {
            transform: rotate(45deg);
            margin-top: 5px;
          }
        }
      }
    }
  }

  // collapsable data tables for small screens
  // based on Aaron Gustafson's technique: http://blog.easy-designs.net/archives/2013/02/02/responsive-tables/
  &.collapsed {
    width: auto;

    &,
    tbody,
    tbody tr,
    tbody th,
    tbody td {
      display: block;
      border: none;
      padding: 0;
      @include alignleft;
      width: auto !important;
      white-space: normal;
    }

    thead {
      display: none;
    }

    tbody {
      tr {
        padding: 6px 0;
        border-bottom: 1px dotted var(--hairline-color);

        &:after {
          @include clearafter;
        }
      }

      th,
      td {
        padding: 2px 0 !important;
      }

      td {
        &:empty {
          display: none;
        }
      }

      [data-title] {
        @include margin-right(0);

        &:before {
          margin-right: 5px;
          content: attr(data-title) ':';
          font-weight: bold;
        }

        form {
          display: inline-block;
        }
      }
    }
  }
}

.datatablesorthelper,
.editabletablesorthelper {
  background-color: var(--white);
  @include shadow;
}

.datatablesorthelper,
.datatablesorthelper .element,
.datatablesorthelper a {
  cursor: move !important;
}

.datatablesorthelper tr:first-child th,
.datatablesorthelper tr:first-child td {
  border-top: none !important;
}

.datatablesorthelper tr:last-child th,
.datatablesorthelper tr:last-child td {
  border-bottom: none !important;
}

/* elements */
$smallThumbSize: 34px;
$largeThumbSize: 120px;
$statusSize: 10px;
$baseElementSidePadding: 7px;
$elementInnerSpacing: 5px;

.element {
  position: relative;
  cursor: default;
  user-select: none;
  font-weight: normal;
  border-radius: var(--small-border-radius);
  max-width: 100%;

  .label {
    .segment:after {
      display: inline-flex !important;
      position: relative;
      top: -2px;
      @include margin(0, 5px, 0, 2px);
      @include angle(right, var(--light-text-color), 1px);

      .sel & {
        @include angle(right, var(--white), 1px);
      }
    }
  }

  &:focus,
  li:focus & {
    background-color: var(--gray-050);
  }

  &.sel,
  li.sel &,
  li.active-drop-target & {
    --text-color: var(--white);
    --medium-dark-text-color: var(--white);
    --medium-text-color: var(--white);
    --light-text-color: var(--white);
    --link-color: var(--white);
    --ui-control-color: var(--white);
    @include custom-color-focus-ring(hsl(var(--light-focus-hsl)));
    color: var(--white);
    background-color: var(--dark-sel-color) !important;
  }

  &.sel,
  li.sel & {
    cursor: default;

    &.hasthumb {
      .elementthumb {
        img {
          box-shadow: 0 0 0 1px transparentize($darkSelColor, 0.9),
            0 6px 4px -4px transparentize($darkSelColor, 0.8);
        }
      }
    }
  }

  .offset-drag-helper & {
    background-color: var(--gray-050);
    @include shadow;
  }

  &.hasthumb {
    .elementthumb:not(:empty) {
      position: absolute;
      display: flex;
      justify-content: center;
      -ms-flex-pack: center;
      align-items: center;
      -ms-flex-align: center;

      img,
      svg {
        display: block;
        flex-shrink: 0;
        pointer-events: none;
        border-radius: var(--small-border-radius);
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }

      svg {
        width: 100%;
        height: 100%;
      }

      &.rounded {
        img,
        svg {
          border-radius: 50%;
        }
      }
    }
    .elementthumb.open-preview {
      cursor: pointer;
    }
  }

  &.error {
    [data-icon='alert'] {
      margin-left: var(--xs);
    }
  }

  .label {
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom; // https://stackoverflow.com/a/37427386/1688568
    text-overflow: ellipsis;
    max-width: 100%;

    .draft-label {
      display: inline-block;
      @include margin(-1px, 0, -1px, 7px);
      padding: 1px 5px;
      font-weight: normal;
      text-decoration: none !important;
      color: var(--medium-text-color);
      background: var(--gray-100);
      border-radius: var(--large-border-radius);
    }
  }

  &.small,
  &.large:not(.hasthumb) {
    display: inline-block;
    padding: $baseElementSidePadding;
    box-sizing: border-box;

    &.hasstatus {
      @include padding-left(
        $baseElementSidePadding + $statusSize + $elementInnerSpacing
      );

      .status {
        position: absolute;
        @include left($baseElementSidePadding);
        top: calc(50% - 5px);
      }

      .icon:not(.delete) {
        position: absolute;
        @include left($baseElementSidePadding - 1);
        top: calc(50% - 11px);
      }
    }

    &.hasthumb {
      @include padding-left($smallThumbSize + $elementInnerSpacing);

      .elementthumb:not(:empty) {
        top: calc(50% - 17px);
        @include left(0);
        width: $smallThumbSize;
        height: $smallThumbSize;

        &.checkered img {
          @include checkered-bg(8px);
        }
      }

      &.hasstatus {
        @include padding-left(
          $smallThumbSize + $elementInnerSpacing * 2 + $statusSize
        );

        .status {
          @include left($smallThumbSize + $elementInnerSpacing);
        }
      }
    }
  }

  &.large.hasthumb {
    display: block;
    padding: #{$baseElementSidePadding + $largeThumbSize + $elementInnerSpacing}
      $baseElementSidePadding $baseElementSidePadding;
    width: #{120px + $baseElementSidePadding + $baseElementSidePadding};
    box-sizing: border-box;

    &.hasstatus {
      @include padding-left(
        $baseElementSidePadding + $statusSize + $elementInnerSpacing
      );

      .status {
        position: absolute;
        @include left($baseElementSidePadding);
        top: #{$baseElementSidePadding + $largeThumbSize + $elementInnerSpacing +
          6};
      }
    }

    .elementthumb:not(:empty) {
      top: $baseElementSidePadding;
      @include left(0);
      width: 100%;
      height: $largeThumbSize;

      &.checkered img {
        @include checkered-bg(15px);
      }
    }

    .label {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: normal;
    }
  }

  &.removable {
    .label {
      @include padding-right(20px);
    }

    .delete:before {
      color: var(--ui-control-color);
    }

    &.small,
    &.large:not(.hasthumb) {
      .delete {
        position: absolute;
        top: calc(50% - 11px);
        @include right($baseElementSidePadding);
      }
    }

    &.large.hasthumb {
      .delete {
        position: absolute;
        @include right($baseElementSidePadding);
      }
    }
  }

  &:not(.removable) {
    .delete {
      display: none;
    }
  }
}

$checkboxPadding: $checkboxSize + 4;

.element-index {
  .source-path {
    background-color: var(--gray-050);
    border-radius: 3px;
    --ui-control-bg-color: var(--light-sel-color);
    --ui-control-hover-bg-color: #{darken($lightSelColor, 5%)};
    --ui-control-active-bg-color: #{darken($lightSelColor, 10%)};

    .chevron-btns {
      display: inline-flex;
      position: relative;
      background: $white;
      --ui-control-height: calc((30rem / 16)); // 1rem == 16px

      &:before {
        display: block;
        content: '';
        font-size: 0;
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        @include right(0);
        border-top: calc(var(--ui-control-height) / 2) solid $grey050;
        @include border-left(
          calc(var(--ui-control-height) / 2) solid transparent
        );
      }

      &:after {
        display: block;
        content: '';
        font-size: 0;
        position: absolute;
        top: calc(var(--ui-control-height) / 2);
        @include right(0);
        border-bottom: calc(var(--ui-control-height) / 2) solid $grey050;
        @include border-left(
          calc(var(--ui-control-height) / 2) solid transparent
        );
      }

      .btn {
        --ui-control-border-radius: 3px;
      }
    }

    .btn.settings {
      box-shadow: 0 0 0 2px var(--white);
    }
  }

  &.main {
    .source-path {
      margin: -22px -22px 24px;
    }
  }

  .elementselectormodal & {
    .source-path {
      margin-bottom: 2px;
    }
  }
}

.elements {
  position: relative;

  &:not(.busy) {
    .update-spinner {
      display: none;
    }
  }

  &.busy {
    min-height: 200px;

    &:after {
      display: block;
      content: '';
      font-size: 0;
      position: absolute;
      top: 0;
      left: -24px;
      width: calc(100% + #{24 + 24}px);
      height: 100%;
      background: transparentize($white, 0.25);
      border-radius: var(--large-border-radius);
    }

    .update-spinner {
      z-index: 1;
    }
  }

  .header {
    margin: -22px -22px 24px;
    padding: 14px 24px;
    background-color: var(--gray-050);
    box-shadow: none;
    border-radius: var(--small-border-radius);

    &:after {
      content: '';
    }

    .selectallcontainer {
      display: flex;
      align-items: center;
      gap: $elementInnerSpacing;
      cursor: default;
    }
  }

  .tableview tr.draggee th,
  .tableview tr.draggee td,
  .thumbsview li.draggee {
    opacity: 0.25;
  }

  // table views
  .tableview {
    .move,
    .toggle {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      @include margin-left(calc(var(--touch-target-size) * -1));
      width: var(--touch-target-size);
      height: var(--touch-target-size);
    }

    .move {
      font-size: 11px;
      text-decoration: none;
    }

    .toggle {
      text-align: center;
      padding: 4px;
      border-radius: 50%;
    }

    .move + .toggle {
      @include margin-left(calc(var(--touch-target-size) * -2));
    }

    .enabled-label {
      margin-left: var(--xs);
    }

    tr.disabled {
      opacity: 1;

      & > th > *,
      & > td {
        opacity: 0.25;
      }

      & > th > button.toggle {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  // thumbs views
  .thumbsview {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

    li {
      position: relative;
      @include margin(0, 1px, 1px, 0);
      max-width: 226px;

      &:hover {
        .element {
          background-color: var(--gray-050);
        }
      }

      .element.large.hasthumb {
        width: unset;
      }

      &.has-checkbox {
        .element.hasthumb {
          &:not(.hasstatus) {
            @include padding-left(
              $baseElementSidePadding + $checkboxSize + $elementInnerSpacing
            );
          }

          &.hasstatus {
            @include padding-left(
              $baseElementSidePadding + $checkboxSize + $elementInnerSpacing +
                $statusSize + $elementInnerSpacing
            );

            .status {
              @include left(
                $baseElementSidePadding + $checkboxSize + $elementInnerSpacing
              );
            }
          }
        }

        .checkbox {
          position: absolute;
          top: $baseElementSidePadding + $largeThumbSize + $elementInnerSpacing +
            (20px - $checkboxSize) * 0.5;
          @include left($baseElementSidePadding);
        }
      }

      &.sel {
        a {
          cursor: pointer !important;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.export-form {
  position: relative;
  .spinner {
    position: absolute;
    bottom: 0;
    @include right(-24px);
  }
}

/* structures */
.structure {
  position: relative;
  z-index: 1;

  li {
    @include padding-left(8px);

    &.collapsed > ul {
      display: none;
    }

    .row:hover > .icon,
    &.draghelper > .row .move,
    .add.active {
      opacity: 1;
    }

    &.draghelper {
      & > .row {
        .add {
          opacity: 0;
        }

        .move:before {
          color: var(--link-color);
        }
      }
    }

    &.draginsertion {
      position: relative;
      @include margin(-1px, 0, -1px, 8px);
      @include padding-left(0);
      height: 2px;
      background-color: var(--link-color) !important;
      @include border-left(none);
      border-radius: 1px;
    }

    .toggle {
      position: relative;
      z-index: 1;
      @include floatleft;
      @include margin(10px, -8px, 0, -12px);
      padding: 4px;
    }

    .row:after {
      @include clearafter;
    }

    .move,
    .add {
      @include margin(5px, 5px, 0, 0);
      opacity: 0;
      transition: opacity linear 100ms;
    }

    .add {
      padding: 0 5px;

      &:before {
        content: 'downangle';
        color: var(--dark-hairline-color);
      }

      &:not(.disabled):hover:before,
      &.active:before {
        color: var(--link-color);
      }
    }

    .checkbox {
      @include floatleft;
      @include margin(7px, 0, 0, 7px);
    }
  }

  ul {
    @include margin-left(-3px);

    li {
      @include padding-left(38px);
      background-repeat: no-repeat;
      --background-position-x: 0;
      --background-position-y: -2px;
      background-position: var(--background-position-x)
        var(--background-position-y);

      body.ltr & {
        background-image: url(../images/branch.png);
      }

      body.rtl & {
        --background-position-x: 100%;
        background-image: url(../images/branch_rtl.png);
      }

      &:not(:last-child):not(.last) {
        @include padding-left(37px);
        @include border-left(1px solid var(--gray-200));

        body.ltr & {
          --background-position-x: -1px;
        }

        body.rtl & {
          --background-position-x: calc(100% + 1px);
        }
      }

      &.draginsertion {
        @include margin-left(38px);
      }
    }
  }

  .row {
    &.draghover {
      .element {
        z-index: 2;
        border-radius: 15px;
        box-shadow: inset 0 0 0 2px var(--link-color);
      }
    }

    &.droptarget {
      border-radius: 5px;
      box-shadow: inset 0 0 0 2px var(--link-color);
    }
  }
}

/* element select fields */
.elementselect {
  position: relative;
  min-height: 34px;
  margin-top: -7px;

  .elements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.flex-row {
      flex-direction: row;
    }

    &.flex-wrap {
      flex-wrap: wrap;
    }

    li {
      max-width: 100%;
    }
  }

  .elements:after {
    @include clearafter;
  }

  .element {
    @include margin(7px, 7px, 0, 0);
  }

  .flex,
  .btn {
    clear: both;
  }

  .element {
    z-index: 1;

    &.small {
      max-width: 100%;

      .label {
        display: block;
        max-width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .flex {
    padding-top: 7px;
  }

  .structure ul {
    @include margin-left(12px);

    li {
      --background-position-y: 0;

      .element {
        margin: 0;
      }
    }
  }
}

/* editable tables */
table.editable {
  border-radius: var(--large-border-radius);
  border: 1px solid var(--gray-200);

  th,
  td.heading,
  td.action {
    color: var(--medium-text-color);
    font-weight: normal;
    background-color: var(--gray-050);
  }

  thead,
  tbody {
    tr {
      th {
        padding: 6px 10px;
      }
    }
  }

  thead {
    tr {
      th {
        border-bottom: 1px solid var(--hairline-color);

        &.has-info {
          @include padding-right(calc(15px + 1em));
        }

        span.info {
          position: absolute;
          margin-left: 5px;
        }
      }
    }
  }

  tbody {
    tr {
      &:not(:first-child) {
        th,
        td {
          border-top: 1px solid var(--hairline-color);
        }
      }

      &:last-child {
        td:first-child {
          @include border-bottom-left-radius(var(--large-border-radius));

          textarea,
          input.text {
            @include border-bottom-left-radius(
              calc(var(--large-border-radius) - 1px)
            );
          }
        }
      }

      td:not(:first-child),
      th ~ td:not(.hidden) ~ td {
        @include border-left(1px solid var(--hairline-color));
      }

      th {
        // Set a dark border-left for the first <td> that follows a <th>, if there is one.
        // This is a ridiculous CSS hack since there's no operator/pseudo-class that mimics jQuery's next(selector) function.
        // If there was it could have been as simple as: th ??? td:not(.hidden) { dark left border }
        // kudos to Mark Huot for coming up with it!
        & ~ td:not(:first-child) {
          @include border-left(1px solid #dbdddf);
        }
      }

      td {
        vertical-align: top;
        text-align: center;
        background-color: var(--white);
        padding: 4px 10px;

        &.focus {
          position: relative;
          box-shadow: inset 0 0 0 1px var(--hairline-color);
        }

        &.textual {
          padding: 0;

          .editable-table-preview {
            @include alignleft;
            white-space: pre-wrap;

            & + textarea {
              opacity: 0;
              height: 0;
              min-height: 0;
              padding-top: 0;
              padding-bottom: 0;
            }
          }

          textarea {
            resize: none;
          }

          pre {
            @include alignleft;
            white-space: pre-wrap;
          }
        }

        &.lightswitch-cell {
          padding-top: 9px;
          padding-bottom: 9px;

          .lightswitch {
            display: block;
            margin: 0 auto;
          }
        }

        &.checkbox-cell {
          padding-top: 10px;
          padding-bottom: 10px;

          .checkbox-wrapper {
            display: block;
            margin: -2px auto 0;
            width: 16px;
            height: 16px;
          }
        }

        &.error {
          box-shadow: inset 0 0 0 1px var(--error-color);
        }

        &.disabled {
          position: relative;
          opacity: 1;

          &:after {
            content: '';
            font-size: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparentize($grey050, 0.25);
            user-select: none;
          }
        }

        &.action {
          padding: 4px 7px;

          & + td.action {
            @include border-left(none);
            @include padding-left(0);
          }
        }

        .flex > * {
          margin-bottom: 0;
        }
      }
    }

    textarea,
    textarea.text,
    input.text,
    pre,
    .editable-table-preview {
      display: block;
      width: 100%;
      border: none;
      box-shadow: none;
      border-radius: 0;
      padding: 7px 10px;
      line-height: 20px;
      background-color: transparent;
      overflow: hidden;
      transition: none;
      box-sizing: border-box;
    }

    .color-container {
      display: block;
      position: relative;

      & > .color,
      .color-input {
        margin-bottom: 0;
      }

      & > .color {
        position: absolute;
        top: 10px;
        @include left(10px);
        z-index: 1;
      }

      .color-hex-indicator {
        @include left(32px);
      }

      .color-input {
        @include padding-left(calc(32px + 1em));
      }
    }

    .datewrapper,
    .timewrapper {
      display: block;
      width: calc(100% - 29px);

      .text + div[data-icon] {
        top: 6px;
        @include left(10px);
      }
    }
  }

  &:not(.static) {
    td.textual {
      cursor: text;
    }
  }

  & + .btn.add {
    display: block;
    width: 100%;
  }

  &:not(.hidden) + .btn.add {
    border-top-width: 0;
    border-radius: 0 0 var(--medium-border-radius) var(--medium-border-radius);
  }
}

.border-box,
.shadow-box {
  & + .buttons {
    margin-top: 7px;
  }
}

/* ----------------------------------------
/*  Nav
/* ----------------------------------------*/

ul.tree,
.tree ul {
  @include margin-left(20px);
}

.tree li .toggle {
  @include margin(7px, 0, 0, -15px);
}

/* status icons */
.status {
  display: inline-block;
  position: relative;
  @include margin-right(10px);
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-radius: 100%;
  box-sizing: border-box;
  background-clip: border-box;

  body.use-shapes & {
    &.all {
      rotate: 45deg;
      background-image: linear-gradient(30deg, #184cef, #e5422b);
    }

    &.pending {
      background-color: transparent;
      border-style: solid;
      border-width: 0 5px 10px 5px;
      border-color: transparent transparent var(--pending-color) transparent;
      border-radius: 1px;
    }

    &.all,
    &.off,
    &.suspended,
    &.expired {
      border-radius: 2px;
      transform: scale(90%);
    }
  }
}

.status:not(.on):not(.live):not(.active):not(.enabled):not(.all):not(
    .pending
  ):not(.off):not(.suspended):not(.expired):not(.yellow):not(.orange):not(
    .red
  ):not(.pink):not(.purple):not(.blue):not(.green):not(.turquoise):not(
    .light
  ):not(.grey):not(.black) {
  border-color: var(--ui-control-color);
}

.green,
.status.on,
.status.live,
.status.active,
.status.enabled {
  background-color: var(--enabled-color);
}

.status.all {
  background-image: linear-gradient(60deg, #184cef, #e5422b);
  background-origin: border-box;
}

.orange,
.status.pending {
  background-color: var(--pending-color);
}

.red,
.status.off,
.status.suspended,
.status.expired {
  background-color: var(--disabled-color);
}

.yellow {
  background-color: var(--yellow-300);
}

.pink {
  background-color: var(--pink-400);
}

.purple {
  background-color: #9b59b6;
}

.blue {
  background-color: var(--blue-600);
}

.turquoise {
  background-color: var(--teal-300);
}

.status.light {
  background-color: var(--gray-100);
}

.grey {
  background-color: var(--gray-300);
}

.black {
  background-color: var(--gray-800);
}

.status.white,
.status.disabled {
  opacity: 1;
}

/* ----------------------------------------
/*  Condition builders
/* ----------------------------------------*/

.condition-container {
  margin: 24px 0;
}

.condition:not(:empty) + .condition-footer .btn.add {
}

.condition-rule,
.condition-footer {
  padding: 7px;
}

.condition-footer {
  border: 1px dashed var(--medium-hairline-color);
  border-radius: var(--large-border-radius);

  .condition:not(:empty) + & {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .spinner:not(.loading) {
    display: none;
  }
}

.condition-rule {
  margin: 0;
  border: 1px solid var(--hairline-color);
  background-color: var(--gray-050);

  &:first-child {
    border-top-left-radius: var(--large-border-radius);
    border-top-right-radius: var(--large-border-radius);
  }

  & + .condition-rule {
    border-top-width: 0;
  }

  & > .rule-move,
  & > .rule-actions {
    margin-top: 5px;
  }

  & > .rule-body {
    .lightswitch {
      margin-top: 6px;
      display: block;
    }

    .selectize {
      min-width: 100px;
    }

    .text.fullwidth {
      min-width: 100px;
      max-width: 100%;
    }
  }
}

/* ----------------------------------------
/*  Progress bar
/* ----------------------------------------*/

.progressbar {
  border-radius: 6px;
  border: 2px solid var(--gray-700);
  padding: 2px;
  position: absolute;
  left: 20%;
  width: 60%;
  z-index: 1000;
}

.progressbar-inner {
  border-radius: 2px;
  height: 4px;
  background-color: var(--gray-700);
}

.progressbar:not(.pending) .progressbar-inner {
  width: 0;
  transition: width linear 100ms;
}

.progressbar.pending .progressbar-inner {
  @include striped-bg(17.6776695297px, var(--gray-700)); // sqrt(25^2 / 2);
  body.ltr & {
    animation-name: pendingprogress-ltr;
  }
  body.rtl & {
    animation-name: pendingprogress-rtl;
  }
  animation-timing-function: linear;
  animation-duration: 250ms;
  animation-iteration-count: infinite;
}

@keyframes pendingprogress-ltr {
  from {
    background-position: 0;
  }
  to {
    background-position: 25px;
  }
}

@keyframes pendingprogress-rtl {
  from {
    background-position: 0;
  }
  to {
    background-position: -25px;
  }
}

.elementselect .progress-shade {
  background-color: transparentize($white, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  @include left(0);
  display: none;
}

.elementselect.uploading {
  position: relative;
}

.elementselect.uploading .progress-shade {
  display: block;
  z-index: 2;
}

// Plugin installers

.missing-component {
  padding: 7px 10px !important;
  max-width: 400px;
  background-color: var(--gray-050) !important;

  .error {
    margin: 0;
  }

  .install-plugin {
    margin: 7px 0 -7px;
    border-top: 1px solid var(--hairline-color);
    position: relative;
    @include padding(10px, 0, 10px, 40px);

    .icon {
      width: 32px;
      height: 32px;
      position: absolute;
      top: calc(50% - 16px);
      @include left(0);

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    h3 {
      flex: 1;
      margin: 8px 0 !important;
    }

    .btn {
      margin: 0;
    }
  }
}

/* ----------------------------------------
/*  Panes, Modals and HUDs
/* ----------------------------------------*/

.pane {
  @include pane;
  position: relative;
  margin: 14px 0;
  padding: 24px;
  border-radius: var(--large-border-radius);
  word-wrap: break-word;
  box-sizing: border-box;

  .pane &,
  #content & {
    background-color: var(--gray-050);
    border: 1px solid var(--hairline-color);
    box-shadow: none;
  }

  &.loading {
    min-height: 200px;

    &:after {
      display: block;
      content: '';
      font-size: 0;
      position: absolute;
      top: 0;
      left: -24px;
      width: calc(100% + #{24 + 24}px);
      height: 100%;
    }
  }
}

.pane-header {
  margin: calc(var(--xl) * -1) var(--neg-padding) var(--xl);
  padding: var(--s) var(--xl);
  min-height: 50px;
  box-sizing: border-box;
  box-shadow: inset 0 -1px 0 0 transparentize($grey300, 0.75);
  border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
  background-color: var(--gray-050);

  .header-btn {
    margin: 2px 0;
    @include header-btn;
  }

  .spinner {
    margin: 0 !important;
  }
}

.pane-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: calc(var(--s) * -1) calc(var(--padding) * -1);

  &:first-child {
    border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
    overflow: hidden;
  }

  [role='tablist'] {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.scrollable {
      body.ltr & {
        mask-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 1) 24px
        );
      }
      body.rtl & {
        mask-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 1) 24px
        );
      }
    }

    [role='tab'] {
      --tab-label-color: var(--light-text-color);

      &.error {
        --highlight-color: var(--error-color) !important;
        --tab-label-color: var(--error-color) !important;
      }

      &,
      .tab-label {
        border-radius: 2px 2px 0 0;
      }

      .tab-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 0 var(--padding);
        white-space: nowrap;
        color: var(--tab-label-color);
        height: 50px;
      }

      &:hover {
        text-decoration: none;
      }

      @include focus-styles {
        box-shadow: none;

        .tab-label {
          box-shadow: var(--inner-focus-ring);
        }
      }

      &:not(.sel) {
        &:hover {
          background-color: transparentize($grey300, 0.85);
        }
      }

      &.sel {
        --highlight-color: var(--gray-500);
        --tab-label-color: var(--text-color);
        position: relative;
        z-index: 1;
        cursor: default;
        box-shadow: inset 0 2px 0 var(--highlight-color),
          0 0 0 1px $hairlineColor, 0 2px 12px transparentize($grey200, 0.5);
        background-color: var(--white);
      }

      span[data-icon] {
        @include margin-left(4px);
      }
    }
  }

  &:first-child {
    [role='tablist'] {
      [role='tab'] {
        &:first-child {
          &,
          .tab-label {
            @include border-top-left-radius(var(--large-border-radius));
          }
        }
      }
    }
  }

  .menubtn {
    margin: 0 var(--xl);

    &.error {
      span[data-icon] {
        margin-right: 2px;
      }
    }
  }
}

/* detail sidebars */
.details {
  --spacing: var(--l);
  padding: 0 var(--padding) var(--spacing);

  .meta,
  .field,
  hr {
    margin-left: var(--neg-padding);
    margin-right: var(--neg-padding);
  }

  .meta {
    margin-bottom: var(--spacing);

    &:not(.read-only) {
      background-color: var(--gray-050) !important;
    }

    &.read-only {
      color: var(--medium-text-color);

      & > .data {
        align-items: baseline;
        min-height: auto;

        & > .heading,
        & > .value {
          padding: 6px 0;
        }

        &:first-child {
          & > .heading,
          & > .value {
            padding-top: 0;
          }
        }

        &:last-child {
          & > .heading,
          & > .value {
            padding-bottom: 0;
          }
        }
      }
    }

    &.warning {
      padding-top: var(--m);
      padding-bottom: var(--m);
      color: var(--text-color) !important;
      background-color: var(--yellow-050) !important;
      box-shadow: 0 0 0 1px var(--yellow-300),
        0 2px 12px transparentize($grey200, 0.5);

      p {
        margin-bottom: 5px;
      }

      .btn {
        background-color: var(--yellow-300);

        &:hover,
        &:focus {
          background-color: darken($yellow300, 10%);
        }

        &:active {
          background-color: darken($yellow300, 15%);
        }
      }
    }

    & > .field,
    & > .data {
      margin: 0 var(--neg-padding) !important;

      & > .heading > label,
      & > .heading {
        color: var(--gray-600);
      }
    }

    & > .field > .status-badge {
      @include left(0);
    }

    .text::placeholder,
    .datewrapper .text + div[data-icon],
    .timewrapper .text + div[data-icon] {
      color: var(--gray-500);
    }

    .ui-datepicker {
      @include margin(0, 0, 0, -8px);
    }
  }

  hr {
    margin: var(--spacing) 0;
    border-top-color: var(--hairline-color);
  }

  @include placeholder-styles(var(--gray-500));

  .text {
    background-color: transparent !important;
    resize: none;
  }

  & > .field .heading,
  & > fieldset > legend {
    margin: 0 0 var(--s);
  }

  & > .field > .heading > label,
  & > fieldset > legend {
    font-weight: normal;
    color: var(--gray-600);
  }

  & > .field > .heading > label {
    margin: 0;
  }

  & > .field {
    margin: 0 0 var(--spacing);

    & > .heading {
      margin-top: 0;
    }

    & > .input > .text.fullwidth {
      margin: 0 var(--neg-padding);
      padding-left: var(--padding);
      padding-right: var(--padding);
      border: none;
      background-color: var(--gray-050) !important;
      width: calc(100% + var(--padding) + var(--padding));
    }
  }

  & > fieldset {
    margin: 0 0 var(--spacing);
  }

  & > .text {
    border-radius: var(--large-border-radius);
    margin-bottom: var(--spacing);

    &:not(:focus) {
      border-color: transparentize($inputColor, 0.6);
    }
  }
}

/* meta panes */
$min2ColWidth: 400px;

.meta {
  padding: 0 var(--padding);
  overflow: visible;

  &,
  & > .flex-fields {
    & > .field,
    & > .data {
      display: flex;
      min-height: 50px;
      box-sizing: border-box;
      flex-wrap: wrap; // for error lists
      justify-content: space-between;
      align-items: center;
      margin: 0 var(--neg-padding) !important;
      padding: 0 var(--padding);
      transition: padding-left linear 100ms, padding-right linear 100ms;

      &.nested {
        @include padding-left(38px);
      }

      &.add {
        background-color: darken($grey050, 2%);

        &:before {
          position: absolute;
          @include left(0);
          width: 31px;
          @include alignright;
          @include icon;
          content: 'plus';
          color: var(--light-text-color);
        }

        .input {
          width: 100%;
        }
      }

      & > .heading {
        margin: 0;

        @media screen and (min-width: $min2ColWidth) {
          flex: 0 0 104px;
          @include margin-right(var(--s));
          line-height: 18px;
        }
      }

      &.lightswitch-field > .heading {
        flex: 1;
      }

      & > .input {
        .flex {
          flex-wrap: nowrap;

          & > * {
            margin-bottom: 0;
          }
        }
      }
    }

    & > .field > .heading {
      padding-top: var(--s);

      @media screen and (min-width: $min2ColWidth) {
        padding: 14px 0;
      }

      & > .copytextbtn {
        display: none;
      }
    }

    & > .data > .heading {
      padding: var(--s) 0;
    }

    & > .field > .heading > label,
    & > .data > .heading {
      color: var(--medium-text-color);
    }

    & > .field > .input,
    & > .data > .value {
      padding: var(--s) 0;
      width: 100%;

      @media screen and (min-width: $min2ColWidth) {
        width: calc(100% - 112px);
      }
    }

    & > .data > .value {
      display: flex;
      align-items: center;

      & > [data-icon='draft'] {
        margin-top: -2px;
        @include margin-right(8px);
      }
    }

    & > .field.lightswitch-field > .input {
      flex: 0;
      width: auto;
    }

    & > .field {
      &.has-errors {
        border: 1px solid var(--error-color) !important;

        &:first-child {
          border-top-left-radius: var(--large-border-radius);
          border-top-right-radius: var(--large-border-radius);
        }

        &:last-child {
          border-bottom-left-radius: var(--large-border-radius);
          border-bottom-right-radius: var(--large-border-radius);
        }

        & + .field {
          border-top: none !important;
        }
      }

      & > .heading {
        & > label,
        & > legend {
          font-weight: normal;
        }
      }

      & > .input {
        &,
        & > .flex,
        & > .flex > .textwrapper,
        & > .datewrapper,
        & > .timewrapper,
        & > .datetimewrapper > .datewrapper,
        & > .datetimewrapper > .timewrapper {
          & > .text {
            display: block;
            margin: calc(var(--s) * -1) 0;
            padding: 14px 0;
            border-radius: 0;
            background-color: transparent;
            border: none !important;
          }
        }

        & > .datewrapper,
        & > .timewrapper,
        & > .datetimewrapper > .datewrapper,
        & > .datetimewrapper > .timewrapper {
          background-color: transparent;
          .text + div[data-icon] {
            @include left(0);
          }
        }

        & > .datetimewrapper {
          gap: 0;

          & > .datewrapper {
            width: 55%;
          }

          & > .timewrapper {
            width: 45%;
          }

          & > .clear-btn {
            @include margin-right(-24px);
          }
        }

        & > .datewrapper,
        & > .timewrapper {
          display: block;
          width: 100%;
        }
      }

      & > ul.errors {
        margin: 0;
        padding: 0 0 6px;
        width: 100%;
        list-style-type: none;
      }

      & > .clear-btn {
        @include margin-right(var(--neg-padding));
      }
    }
  }

  & > .field:not(:first-child):not(.first-child),
  & > .flex-fields + .field:not(.first-child) {
    border-top: 1px solid var(--hairline-color);
  }

  & > .flex-fields {
    h2,
    blockquote.note {
      margin: 0 -24px !important;
      padding: 14px 24px;
      background-color: darken($grey050, 2%);
    }

    blockquote.note {
      border-radius: 0;
      border: none;
    }

    hr {
      margin: 0 -24px;
    }
  }
}

.meta > .field > .input > .select {
  display: block;
  margin: calc(var(--s) * -1) 0;
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;

  &:after {
    @include right(0);
  }

  & + .spinner {
    position: absolute;
    top: calc(50% - 17px);
    @include right(-24px);
  }

  select {
    @include padding(var(--s), 12px, var(--s), 0);
    width: 100%;
    background-color: transparent;
  }
}

.body {
  position: relative;
}

.slideout-container,
.slideout,
.modal,
.hud {
  z-index: 100;
  box-sizing: border-box;
}

.modal,
.hud {
  @include modal;
}

.slideout-shade {
  opacity: 0;
  transition: opacity linear 250ms;

  &.so-visible {
    opacity: 1;
  }
}

.slideout-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available; // h/t https://twitter.com/AllThingsSmitty/status/1254151507412496384
  pointer-events: none;

  &.so-lp {
    position: absolute;
    top: var(--m);
    left: var(--m);
    width: calc(100% - var(--m) * 2);
    height: calc(100vh - var(--m) * 2);
  }

  body.has-debug-toolbar & {
    height: calc(100vh - 42px);
  }
}

.slideout {
  position: absolute;
  background-color: var(--white);
  box-shadow: 0 0 0 1px transparentize($grey400, 0.75),
    0 25px 100px transparentize($grey900, 0.5) !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 24px var(--padding);
  pointer-events: all;
  container-type: inline-size;

  &.so-mobile,
  &.so-lp {
    width: 100% !important;
    height: 100% !important;
    left: 0;
    transition: top linear 250ms;
    will-change: top;
  }

  &.so-mobile {
    --padding: 14px;
    --neg-padding: -14px;
  }

  &:not(.so-mobile) {
    border-radius: var(--large-border-radius);
  }

  &:not(.so-mobile):not(.so-lp) {
    top: 8px;
    width: calc(50% - 8px);
    height: calc(100% - 16px);

    @media screen and (prefers-reduced-motion: no-preference) {
      body.ltr & {
        transition: left linear 250ms;
        will-change: left;
      }

      body.rtl & {
        transition: right linear 250ms;
        will-change: right;
      }
    }
  }

  & > .pane-header {
    padding-left: var(--padding);
    padding-right: var(--padding);
    z-index: 2;

    & > .so-toolbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--s);
      min-height: calc(50px - 16px);

      & > .pane-tabs {
        width: 1px; // give other elements in the header plenty of room before the tabs take up whatever's left
        flex: 1;
        @include margin-right(0);
      }
    }
  }

  & > .so-body {
    flex: 1;
    margin: -24px var(--neg-padding) 0;
    overflow: hidden auto;
    position: relative;

    &.so-full-details,
    & > .so-sidebar {
      background-color: var(--gray-100) !important;
    }

    &:not(.so-full-details) {
      padding: 24px var(--padding);
    }

    & > .so-sidebar,
    &.so-full-details > .so-content > .details {
      box-sizing: border-box;
      padding: 0 var(--padding) var(--spacing);

      & > .preview-thumb-container {
        margin: 0 var(--neg-padding);
        height: auto;
        min-height: 54px; // make room for the Preview / Edit buttons

        & + .pane-header {
          border-radius: 0;
        }
      }

      .image-actions {
        &.is-mobile {
          margin: calc(var(--spacing) / 2) 0 var(--spacing);
        }
      }

      & > .meta.read-only:first-child {
        margin-top: var(--padding);
      }

      & > .meta.warning {
        box-shadow: none;
        border-bottom: 1px solid var(--yellow-300);
      }

      & > .field {
        & > .input > .text.fullwidth {
          border-radius: 0;
        }
      }

      .notes {
        padding-top: var(--m);
        padding-bottom: var(--m);
      }
    }

    & > .so-sidebar {
      position: absolute;
      top: 0;
      @include pane();
      width: 350px;
      height: 100%;
      max-width: 100%;
      overflow: hidden auto;
      z-index: 1;

      body.ltr & {
        transition: right linear 250ms;
      }
      body.rtl & {
        transition: left linear 250ms;
      }
    }
  }

  & > .so-footer {
    position: relative;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 var(--neg-padding) -24px;
    padding: 8px var(--padding);
    @include pane;
    background-color: var(--gray-050);
    z-index: 3;

    & > .so-extra {
      flex: 0 0 100%;
      margin: 0 var(--neg-padding);
      padding: 0 var(--padding) 8px;
      border-bottom: 1px solid var(--hairline-color);
    }
  }
}

@container (width > 700px) {
  .slideout {
    &.has-sidebar {
      .pane-header:not(.so-visible) {
        display: none;
      }

      .sidebar-btn {
        display: none;
      }

      .so-body {
        display: flex;
        flex-direction: row;
        padding: 0;
        overflow: hidden;

        & > .so-content {
          position: relative;
          z-index: 2;
          padding: 24px;
          width: calc(100% - 350px);
          height: 100%;
          box-sizing: border-box;
          @include border-right(1px solid var(--gray-200));
          overflow: hidden auto;
        }

        & > .so-sidebar {
          position: relative;
          display: block !important;
          top: auto;
          right: auto !important;
          left: auto !important;
          height: 100%;
          box-shadow: none;
        }
      }
    }

    & > .so-footer {
      & > .so-extra {
        margin: 0;
        padding: 0;
        border: none;
        flex: auto 0 1;
      }
    }
  }
}

.header,
.hud-header,
.footer,
.hud-footer,
.body {
  &:after {
    @include clearafter;
  }
}

.header,
.hud-header,
.footer,
.hud-footer {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.header,
.hud-header,
.footer,
.hud-footer {
  background-color: var(--gray-100);
}

.header,
.hud-header {
  border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
  padding: 24px;
  box-shadow: inset 0 -1px 0 var(--hairline-color);

  h1 {
    margin: 0;
  }
}

.footer,
.hud-footer {
  border-radius: 0 0 var(--large-border-radius) var(--large-border-radius);
  padding: var(--s) var(--xl);
  box-shadow: inset 0 1px 0 var(--hairline-color);

  &.flex {
    & > * {
      margin-bottom: 0;
    }
  }
}

.modal .body,
.hud .main {
  padding: 24px;
  overflow: hidden;
  box-sizing: border-box;
}

.pane,
.modal .body {
  .header {
    margin: -24px -24px 24px;
  }

  .footer {
    margin: 24px -24px -24px;
  }
}

.slideout-shade,
.modal-shade,
.hud-shade {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.slideout-shade,
.modal-shade {
  &:not(.dark) {
    background-color: transparentize($grey400, 0.65) !important;
  }

  &.dark {
    background-color: transparentize($grey900, 0.5) !important;
  }
}

.modal {
  position: fixed;
  overflow: hidden;

  &:not(.fitted):not(.fullscreen) {
    width: 66%;
    height: 66%;
    min-width: 600px;
    min-height: 400px;
  }

  &.fitted {
    width: auto;
    height: auto;
    min-width: 0;
    min-height: 0;
  }

  &.fullscreen {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  &.alert .body {
    @include padding-left(76px);

    &:before {
      @include icon;
      @include margin(-6px, 0, 0, -58px);
      @include floatleft;
      content: 'alert';
      font-size: 40px;
      color: var(--light-text-color);
    }
  }

  &.secure .body {
    @include padding-left(76px);

    &:before {
      @include icon;
      @include margin(-14px, 0, 0, -56px);
      @include floatleft;
      content: 'secure';
      font-size: 58px;
      color: var(--light-text-color);
    }
  }

  .resizehandle {
    position: absolute;
    z-index: 1;
    bottom: 0;
    @include right(0);
    width: 24px;
    height: 24px;
    cursor: nwse-resize;
    padding: var(--xs);

    path {
      fill: var(--ui-control-color);
    }

    body.rtl & {
      .ltr {
        display: none;
      }
    }

    body.ltr & {
      .rtl {
        display: none;
      }
    }
  }
}

.hud {
  position: absolute;
  display: none;
  top: 0;

  &.tooltip-hud {
    display: block;
  }

  &.has-footer .tip-bottom {
    background-image: url(../images/hudtip_bottom_gray.png);
  }

  .tip {
    position: absolute;
    z-index: 101;
    background: no-repeat 0 0;
  }

  .tip-left {
    left: -15px;
    width: 15px;
    height: 30px;
    background-image: url(../images/hudtip_left.png);
  }

  .tip-top {
    top: -15px;
    width: 30px;
    height: 15px;
    background-image: url(../images/hudtip_top.png);
  }

  .tip-right {
    right: -15px;
    width: 15px;
    height: 30px;
    background-image: url(../images/hudtip_right.png);
  }

  .tip-bottom {
    bottom: -15px;
    width: 30px;
    height: 15px;
    background-image: url(../images/hudtip_bottom.png);
  }
}

.hud .hud-header,
.hud .hud-footer {
  padding: var(--s) var(--xl);
}

.hud .body {
  overflow: hidden;

  ::-webkit-scrollbar {
    appearance: none;

    &:vertical {
      width: 11px;
    }

    &:horizontal {
      height: 11px;
    }
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid transparent;
    background-color: transparentize($black, 0.5);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--gray-050);
  }
}

/* inline asset previews */

.preview-thumb-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 190px;
  background-color: var(--gray-900);
  margin: 0 var(--neg-padding) var(--spacing);

  &.checkered img {
    background-color: var(--white);
    @include checkered-bg(17px);
  }

  &.editable {
    cursor: pointer;
  }

  &.loading {
    &:after {
      content: '';
      font-size: 0;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: transparentize($grey900, 0.2);
    }

    .spinner {
      color: var(--white);
      z-index: 1;
    }
  }

  #details & {
    border-radius: var(--large-border-radius);
    overflow: hidden;
  }

  .preview-thumb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      display: block;
      max-width: 100%;
      max-height: 190px;
    }
  }
}

.image-actions {
  &.is-mobile {
    margin: calc((var(--spacing) / 2) * -1) var(--neg-padding) var(--spacing);
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.button-fade {
  .buttons {
    opacity: 0;
    position: absolute;
    top: 10px;
    @include right(10px);
    margin: 0;
    transition: opacity linear 100ms;

    .btn {
      --ui-control-color: var(--white);
      --ui-control-hover-color: var(--white);
      --ui-control-active-color: var(--white);
      --interaction-background-color: var(--gray-700);
      background-color: var(--gray-600);
      color: var(--white);
      @include light-on-dark-text;
      @include two-color-focus-ring($light-button: false);

      &:hover {
        background-color: var(--interaction-background-color) !important;
      }

      &:not(.disabled):not(.loading):not(.dashed) {
        &:focus,
        &.focus,
        &:hover {
          background-color: var(--interaction-background-color);
        }
      }
    }
  }

  &:hover,
  &:focus-within {
    .buttons {
      opacity: 1;
    }
  }
}

/* element selector modals */
.elementselectormodal {
  --content-padding: 24px;
  padding-bottom: 50px;
  user-select: none;

  .header {
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;

    & + .body {
      height: calc(100% - 48px) !important;
    }
  }

  .body {
    position: relative;
    height: 100%;

    .spinner.big {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -24px 0 0 -24px;
    }

    .content {
      height: calc(100% + 48px);

      .sidebar {
        position: absolute;
        top: 0;
        @include margin-left(-249px);
        height: 100%;
        overflow: auto;
        padding: var(--content-padding) 0;
      }

      .main {
        margin: -24px;
        padding: var(--content-padding);
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
        position: relative;

        .elements {
          &.busy {
            min-height: calc(100% - 48px);
          }

          .tableview table {
            .element {
              display: inline-block;
            }

            tr {
              th,
              td {
                cursor: default;
              }

              td:first-child {
                @include padding-left(7px);
              }
            }
          }

          .structure .row {
            margin-top: 1px;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    box-sizing: border-box;

    .spinner {
      @include floatright;
      @include margin-right(-24px);
    }
  }
}

/* element selector modals & customize sources modal */
.elementselectormodal,
.customize-sources-modal {
  .main-header {
    display: flex;
    align-items: center;
    margin-bottom: var(--s);
  }

  .main-heading {
    margin-bottom: 0;
  }

  .nav-toggle {
    @include margin-left(4px);
    @include touch-target;
    color: var(--ui-control-color);

    &:hover {
      color: var(--gray-500);
    }
  }

  .sidebar-header {
    display: flex;
    justify-content: flex-end;
    padding: 0 var(--s);
    margin-bottom: var(--s);
  }

  .nav-close {
    @include touch-target;
  }
}

/* element editing HUD */
.element-hud-form {
  .buttons {
    position: relative;

    .spinner {
      position: absolute;
      top: 0;
      @include right(-24px);
    }
  }
}

/* recent activity container */
.activity-container {
  &:not(:last-child) {
    @include margin-right(var(--s));
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--xs);

    li {
      .activity-btn {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: block;

        .elementthumb {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 50px;
          background: var(--gray-050);
          overflow: hidden;
          border: 2px solid var(--white);
          box-shadow: 0 1px 2px 1px transparentize($grey900, 0.9);

          img,
          svg {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }

      &:not(:last-child) {
        @include margin-right(-10px);
      }
    }
  }
}

/* element index view menu */
.element-index-view-menu {
  width: 400px;
  max-width: calc(100% - 20px);
  padding: 0 !important;

  .meta {
    padding-top: var(--s);
    padding-bottom: var(--s);
  }

  .table-columns-field {
    align-items: flex-start;

    .input {
      padding: 13px 0;
    }
  }

  .menu-footer {
    margin: 0 !important;
    padding: var(--s) var(--xl) !important;
    background-color: var(--gray-050);
  }
}

.element-index-view-menu-table-column {
  display: flex;
  align-items: start;
  align-content: stretch;
  margin-bottom: 4px;

  .icon.move {
    margin-top: -3px;
    @include margin-right(10px);
    position: relative;
    z-index: 1;
  }
}

/* sort fields */
.sort-field {
  .input {
    .flex {
      .btngroup {
        .btn {
          width: 42px;
        }
      }

      .flex-grow {
        max-width: calc(100% - 85px - var(--s));
      }

      @media screen and (max-width: 350px) {
        flex-direction: column;

        & > * {
          width: 100%;
        }

        .flex-grow {
          max-width: none;
        }
      }
    }
  }
}

/* element filter HUD */
.element-filter-hud {
  &.loading .body {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body,
  .main {
    overflow: visible;
  }
}

/* Address Cards */
.address-cards {
  display: grid;
  gap: var(--m) var(--m);
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);

  .so-content & {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}

.address-cards__add-btn {
  padding: 1rem;
  min-height: 8rem;
  height: auto;
  width: 100%;
}

.address-card.error {
  border: 1px solid #cf1124;
}

.address-card.error:hover {
  border-color: #cf1124;
}

@media (min-width: 1024px) {
  .address-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .address-cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    .so-content & {
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
  }
}

@media (min-width: 1536px) {
  .address-cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.address-card {
  border: 1px solid #eee;
  border-radius: 0.375rem;
  padding: 1rem;
  min-height: 4rem;
}

.address-card:hover {
  border-color: #ddd;
  background-color: #fafafa;
  cursor: pointer;
}

.address-card .address-card-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.address-card .address-card-header-actions {
  display: flex;
  justify-content: flex-end;
}

.address-card .address-card-label {
  background: #e6f1fe;
  color: #2563eb;
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.75rem;

  .ltr & {
    margin-right: 0.5rem;
  }

  .rtl & {
    margin-left: 0.5rem;
  }
}

/* logout warning/login/elevated session modals */
.logoutwarningmodalshade,
.loginmodalshade {
  z-index: 1001;
}

#logoutwarningmodal,
#loginmodal,
#elevatedsessionmodal,
.prompt {
  width: 500px;
}

#logoutwarningmodal,
#loginmodal {
  z-index: 1001;
}

.prompt {
  height: auto;
  min-height: auto;
}

/* delete user modal */
.deleteusermodal {
  .content-summary {
    margin: -24px -24px 24px;
    padding: 24px;
    background-color: var(--gray-050);
  }

  .options {
    label {
      display: inline-block;
      line-height: 30px;
    }
  }

  .elementselect {
    @include margin-left(10px);
    display: inline-block;
    vertical-align: middle;
  }

  .buttons {
    .spinner {
      @include margin-right(-20px);
    }
  }
}

.dropdownsettingsmodal {
  width: auto;
  height: auto;
  min-width: 0;
  min-height: 0;
  max-width: 400px;

  .body {
    max-height: 100%;
    overflow-y: auto;
  }
}

.previewmodal {
  &.zilch {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* ----------------------------------------
/*  Alternative Text
/* ----------------------------------------*/
.video-transcript {
  background-color: var(--gray-050);
  overflow-y: scroll;
  font-size: 1rem;
  position: relative;

  @include focus-styles {
    // Unset default
    box-shadow: none;
    color: var(--white);
    background-color: var(--gray-800);
  }
}

.video-transcript__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  padding: var(--xl);
}

/* ----------------------------------------
/*  Menus
/* ----------------------------------------*/

.menu,
.ui-datepicker,
.ui-timepicker-list {
  @include menu-styles;
}

.ui-datepicker,
.ui-timepicker-list {
  padding: 0;
}

.menu {
  display: none;
  position: absolute;

  h6 {
    &:first-child {
      margin-top: 14px !important;
    }
  }

  ul {
    &.padded {
      li {
        a,
        .menu-option {
          @include padding-left(24px);

          &[data-icon],
          &.icon,
          &.sel {
            &:before {
              @include floatleft;
              @include margin(3px, 0, 0, -17px);
              font-size: 14px;
              color: var(--ui-control-color);
            }

            &.error:before {
              color: var(--error-color);
            }
          }

          &.sel:not([data-icon]):before {
            content: 'check';
          }
        }
      }
    }

    li {
      a,
      .menu-option {
        @include menu-option-styles;
        font-size: 14px;
        cursor: default;
        -webkit-appearance: none;

        &:not(.flex) {
          display: table !important;
          box-sizing: border-box;
          width: calc(100% + 28px);
          @include alignleft;
        }

        &.flex {
          [data-icon] {
            margin-top: -2px;
          }
        }

        &.sel {
          cursor: default;
        }

        .shortcut {
          @include floatright;
          @include margin-left(14px);
          padding: 0 4px;
          border-radius: var(--medium-border-radius);
          box-shadow: 0 0 0 1px transparentize($grey600, 0.75),
            0 1px 3px -1px transparentize($grey600, 0.5);
        }
      }
    }
  }

  & > .flex {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;

    &.padded {
      @include margin-left(-14px);
      @include padding-left(24px);

      &.sel {
        &:before {
          position: absolute;
          top: 36px;
          @include left(7px);
          content: 'check';
          font-size: 14px;
          color: var(--light-text-color);
        }
      }
    }
  }

  hr {
    margin: 5px -14px;
  }

  .go:after {
    color: inherit;
  }
}

.menubtn,
.menu {
  span.icon {
    display: inline-block;
    margin-top: -1px;
    width: 10px;
    @include margin-right(10px);
    text-align: center;
    font-size: 14px;
    color: var(--ui-control-color);
  }
}

.menu:not(.menu--disclosure) ul li a:not(.sel):not(.disabled):hover,
.menu:not(.menu--disclosure):not(:hover) ul li a:not(.disabled).hover {
  @include menu-option-active-styles;
  --text-color: var(--white);
  --light-text-color: var(--gray-100);
  --ui-control-color: var(--gray-050);
  --ui-control-hover-color: var(--gray-100);
  --ui-control-active-color: var(--gray-100);

  span.icon,
  &:before,
  div.code {
    color: var(--menu-option-active-color);
  }

  &.error {
    color: var(--menu-option-active-color) !important;
  }

  span.light {
    color: var(--white) !important;
  }
}

.menu {
  hr.padded,
  h6.padded {
    @include margin-left(10px);
  }
}

.menu--disclosure ul li {
  & > a,
  & > .menu-option {
    &:hover {
      @include disclosure-link-hover-styles;
    }

    @include focus-styles {
      box-shadow: inset 0 0 0 3px hsla(var(--dark-focus-hsl), 0.7);
    }
  }
}

/* tag select fields */
.tagselect {
  .elements {
    display: inline;
  }

  .element.small {
    clear: none;
  }

  .add {
    position: relative;
    z-index: 1;
    @include margin(7px, 7px, 0, 0);
    display: inline-block;
    width: 12em;

    .text {
      @include padding-right(30px);
    }

    .spinner {
      position: absolute;
      top: 0;
      @include right(5px);
    }
  }

  // todo: why are body.ltr and body.rtl needed here?
  body.ltr &,
  body.rtl & {
    &.elementselect .element {
      float: none !important;
      display: inline-block;
    }
  }
}

.tagmenu {
  ul {
    li {
      a {
        @include padding-left(26px);

        &:before {
          @include floatleft;
          @include margin(3px, 0, 0, -18px);
        }
      }
    }
  }
}

/* selectize */
.selectize-control.single .selectize-input:not(.no-arrow)::after {
  display: none;
}

/* ----------------------------------------
/*  Fields
/* ----------------------------------------*/

.shadow-box {
  border-radius: var(--large-border-radius);
  border: 1px solid var(--gray-200);
  @include shadow;
}

table.shadow-box,
table.editable {
  border-collapse: separate;
  border-spacing: 0;

  thead,
  tbody:first-child,
  caption + tbody {
    tr:first-child {
      th:first-child,
      td:first-child {
        &,
        &.disabled:after {
          border-top-left-radius: var(--medium-border-radius);
        }
      }
      th:last-child,
      td:last-child {
        &,
        &.disabled:after {
          border-top-right-radius: var(--medium-border-radius);
        }
      }
    }
  }
  thead:last-child,
  tbody:last-child {
    tr:last-child {
      th:first-child,
      td:first-child {
        &,
        &.disabled:after {
          border-bottom-left-radius: calc(var(--large-border-radius) - 1px);
        }
      }
      th:last-child,
      td:last-child {
        &,
        &.disabled:after {
          border-bottom-right-radius: calc(var(--large-border-radius) - 1px);
        }
      }
    }
  }
}

.text,
.passwordwrapper,
.border-box,
.matrix-configurator > .field > .input,
.selectize.multiselect .selectize-input,
.multiselect > select {
  @include input-styles;
}

.text,
.passwordwrapper,
.border-box,
.selectize.multiselect .selectize-input {
  &.focus {
    @include input-focused-styles;
  }

  @include focus-styles {
    @include input-focused-styles;
  }
}

input.text,
textarea.text,
.text > input,
.text > textarea,
table.editable textarea,
.selectize.multiselect .selectize-input {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color);
  min-height: 3px;
  box-sizing: border-box;
  appearance: none;
}

.selectize.multiselect .selectize-input {
  line-height: 18px;
}

textarea.text.fullwidth {
  display: block;
}

.multitext .multitextrow {
  &:after {
    @include clearafter;
  }

  &:first-child .text {
    &:first-child {
      @include border-top-left-radius(var(--medium-border-radius));
    }

    &:last-child {
      @include border-top-right-radius(var(--medium-border-radius));
    }
  }

  &:last-child .text {
    &:first-child {
      @include border-bottom-left-radius(var(--medium-border-radius));
    }

    &:last-child {
      @include border-bottom-right-radius(var(--medium-border-radius));
    }
  }

  &:not(:first-child) .text {
    margin-top: -1px;
  }

  .text {
    border-radius: 0;
    float: left;
    box-sizing: border-box;

    &:not(:first-child) {
      @include margin-left(-1px);
    }

    &:first-child {
      &:nth-last-child(1) {
        width: 100%;
      }

      &:nth-last-child(2) {
        width: 50%;
      }

      &:nth-last-child(2) ~ .text {
        width: calc(50% + 1px);
      }
    }

    &.error {
      position: relative;
      z-index: 1;
    }
  }

  .text:focus,
  .selectize.multiselect .selectize-input.focus {
    position: relative;
    z-index: 2;
  }
}

.chars-left {
  position: relative;
  @include floatright;
  @include margin(-27px, 7px, 0, 0);
  color: var(--light-text-color);

  .input.ltr > & {
    float: right !important;
    margin-right: 7px !important;
  }

  .input.rtl > & {
    float: left !important;
    margin-left: 7px !important;
  }

  &.negative-chars-left {
    color: var(--error-color);
  }
}

.field,
fieldset {
  position: relative;
  margin: 24px 0;

  .flex > & {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.field {
  min-inline-size: initial;

  & > .status-badge {
    position: absolute;
    top: 0;
    @include left(0);
    width: 2px;
    height: 100%;
    content: '';
    cursor: help;

    &.modified {
      background-color: var(--blue-600);
    }

    &.outdated {
      background-color: var(--pending-color);
    }
  }

  & > .heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin-top: -5px;
    margin-bottom: 5px;

    & > label,
    & > legend {
      font-weight: bold;
      color: var(--medium-dark-text-color);

      code {
        font-size: 1em !important;
      }

      .info {
        @include margin-left(5px);
      }
    }

    .t9n-indicator {
      @include margin-left(7px);
      color: var(--light-text-color);
    }

    & + .instructions {
      margin-top: -3px;
    }

    // BC
    & > .instructions {
      width: 100%;
    }
  }

  & > .instructions {
    margin-bottom: 5px;
  }

  & > .input {
    position: relative;

    &:after {
      @include clearafter;
    }

    & + .instructions {
      margin: 5px 0 0;
    }

    input:disabled,
    textarea:disabled {
      cursor: not-allowed;
    }
  }

  & > .notice,
  & > .warning {
    margin: 5px 0 0;
  }
}

.field > .instructions,
// BC
.field > .heading > .instructions,
.checkboxfield .instructions {
  color: var(--medium-text-color);

  img,
  video,
  embed,
  iframe {
    max-width: 100% !important;
  }

  ul,
  ol {
    margin: 1em 0;
    @include padding-left(2em);
  }

  ul li {
    list-style-type: disc;
  }

  li + li {
    margin-top: 0.25em;
  }
}

.expand-status-btn {
  @include margin-left(5px);
  width: 30px;
  min-height: 17px;
  padding: 0;
  line-height: 16px;
  border-radius: var(--small-border-radius);
  color: var(--text-color);

  &:before {
    margin: 0;
  }
}

/* toggles and nested fields */
.nested-fields {
  margin: -24px;
  padding: 24px 24px 0;

  &.hidden {
    display: block;
    height: 0;
  }

  & > .field:last-child {
    padding-bottom: 24px;
  }
}

/* checkbox */
input.checkbox {
  opacity: 0;
  position: absolute;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
}

input.checkbox + label,
div.checkbox {
  display: inline-block;
  clear: none;
  position: relative;
  @include padding-left($checkboxSize + 5);
  line-height: 16px;
  min-height: 16px;
  cursor: pointer;

  &,
  &:before {
    // set the border radius on the container too, for (some) focus rings
    border-radius: var(--small-border-radius);
  }

  &:before {
    display: block;
    position: absolute;
    @include left(0);
    top: 0;
    width: var(--checkbox-size) !important;
    height: var(--checkbox-size);
    box-sizing: border-box;
    content: '';
    font-size: 0;
    background-color: hsl(212, 50%, 99%);
    border: 1px solid transparentize($inputColor, 0.6);
    background-clip: padding-box;
  }

  &:empty {
    @include padding-left(var(--checkbox-size));

    &:after {
      content: '';
      font-size: 0;
    }
  }

  .info {
    height: 16px;
  }
}

input.checkbox:disabled + label,
.disabled div.checkbox {
  cursor: not-allowed;
}

input.checkbox:checked + label:before,
div.checkbox.checked:before,
.sel div.checkbox:before,
input.checkbox:indeterminate + label:before,
div.checkbox.indeterminate:before {
  @include icon;
  line-height: var(--checkbox-size);
  color: var(--gray-900);
}

input.checkbox:checked:not(:indeterminate) + label:before,
div.checkbox.checked:not(.indeterminate):before,
.sel div.checkbox:not(.indeterminate):before {
  content: 'check';
  font-size: 15px;
}

input.checkbox:indeterminate + label:before,
div.checkbox.indeterminate:before {
  content: 'minus';
  font-size: 7px;
  text-align: center;
}

body:not(.reduce-focus-visibility) {
  input.checkbox:focus + label:before,
  :focus div.checkbox:before {
    @include input-focused-styles;
  }
}

body.reduce-focus-visibility {
  input.checkbox:focus-visible + label:before,
  :focus-visible div.checkbox:before {
    @include input-focused-styles;
  }
}

.checkbox-icon {
  display: inline-flex;
  padding: 3px;
  background-color: var(--enabled-color);
  color: var(--white);
  border-radius: var(--small-border-radius);

  &:before {
    --checkbox-size: 14px;
    --icon-opacity: 1;
    @include icon;
    content: 'check';
    line-height: var(--checkbox-size);
    font-size: var(--checkbox-size);
  }
}

.monaco-mouse-cursor-text {
  @include focus-styles {
    box-shadow: none !important;
  }
}

fieldset {
  .checkboxfield {
    margin: 5px 0;
  }
}

.checkboxfield {
  .instructions,
  .notice,
  .warning {
    margin-top: 2px;
    @include padding-left($checkboxSize + 5);
  }
}

/* radio */
input.radio {
  opacity: 0;
  position: absolute;
  width: var(--radio-size);
  height: var(--radio-size);
}

input.radio + label,
div.radio {
  display: inline-block;
  clear: none;
  position: relative;
  @include padding-left($radioSize + 5);
  line-height: calc(24 / 14);
  cursor: pointer;
}

input.radio:disabled + label,
.disabled div.radio {
  cursor: not-allowed;
}

input.radio + label:empty,
div.radio:empty {
  @include padding-left(var(--radio-size));
}

/* fixes a RTL bug */
input.radio + label:before,
input.radio + label:after,
div.radio:before,
div.radio:after {
  display: block;
  position: absolute;
  content: '';
  box-sizing: border-box;
  background-clip: padding-box;
  border-radius: 100%;
}

input.radio + label:before,
div.radio:before {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: var(--radio-size);
  height: var(--radio-size);
  background-color: hsl(212, 50%, 99%);
  border: 1px solid transparentize($inputColor, 0.6);
}

input.radio + label:after,
div.radio:after {
  top: calc(50%);
  left: 4px;
  transform: translateY(-50%);
  width: calc(var(--radio-size) - 8px);
  height: calc(var(--radio-size) - 8px);
}

input.radio:checked + label:after,
div.radio.checked:after,
.sel div.radio:after {
  background: var(--gray-900);
}

body:not(.reduce-focus-visibility) {
  input.radio:focus + label:before,
  :focus div.radio:before {
    @include input-focused-styles;
  }
}

body.reduce-focus-visibility {
  input.radio:focus-visible + label:before,
  :focus-visible div.radio:before {
    @include input-focused-styles;
  }
}

/* multiselect */
.multiselect > select {
  color: var(--text-color);
  font-size: 14px;
  appearance: none;

  @include focus-styles {
    @include input-focused-styles;
  }

  option {
    padding: 1px 8px;
  }
}

.text,
.selectize.multiselect .selectize-input {
  padding: 6px 9px;
}

.text {
  background-color: var(--white);

  &:not(.small) {
    box-sizing: border-box;
    min-height: calc(34rem / 16);
  }

  &.small {
    padding: 3px;
  }

  &.readable {
    padding: 16px 18px;
    font-size: 16px;
    line-height: 22px;

    & + .chars-left {
      margin-top: -23px;
    }
  }

  &.clearable {
    @include padding-right(var(--touch-target-size));
  }

  input {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
  }
}

.input.errors > .text,
.input.errors > .border-box,
.input.errors > .passwordwrapper,
.input.errors > .autosuggest-container .text,
.text.error {
  border: 1px solid var(--error-color) !important;
}

.texticon {
  position: relative;
  cursor: text;
  min-width: 130px;

  &.icon {
    &:before {
      position: absolute;
      top: 9px;
      @include left(9px);
      color: var(--ui-control-color);
    }

    .text {
      @include padding-left(26px);
    }
  }

  .texticon-icon {
    position: absolute;
    top: calc((34rem / 16) / 2);
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    @include left(9px);
  }

  .clear-btn {
    position: absolute;
    top: calc(
      (var(--touch-target-size) - 34px) / -2
    ); /* Responds to min-height of text input */
    @include right(0);
    width: var(--touch-target-size);
    height: var(--touch-target-size);
  }

  &.has-filter-btn {
    .text {
      @include padding-right(26px);
    }

    .filter-btn {
      position: absolute;
      top: calc((34rem / 16) / 2);
      transform: translateY(-50%);
      @include right(4px);
      padding: 0 7px 4px;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      border-radius: 13px;

      &:before {
        @include icon;
        content: 'filter';
        color: var(--medium-text-color);
      }

      &:hover {
        background-color: var(--gray-100);
      }

      &:active,
      &.active {
        background-color: var(--gray-350);

        &:before {
          color: var(--white);
        }
      }
    }

    .text {
      @include padding-left(calc(26rem / 16));

      &.clearable {
        @include padding-right(calc(30px + var(--touch-target-size)));
      }
    }

    .clear-btn {
      @include right(30px);
    }
  }
}

.texthint-container {
  position: relative;
  height: 0;
}

.texthint {
  position: absolute;
  top: -1px;
  width: 100%;
  color: var(--light-text-color);
  cursor: text;
}

.passwordwrapper {
  position: relative;

  .password {
    border: none;
    background: transparent;
    padding-right: 4rem;
    box-shadow: none !important;
  }

  .password-toggle {
    color: var(--link-color);
    position: absolute;
    top: 0;
    @include right(9px);
    bottom: 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.datetimewrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.clear-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  cursor: pointer;
  color: var(--ui-control-color);
  border: none;
  padding: 0;
  background: transparent;

  &:before {
    @include icon;
    content: 'remove';
  }

  &:hover {
    color: var(--ui-control-hover-color);
  }

  &:active {
    color: var(--ui-control-active-color);
  }
}

.close-btn {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    height: 15px;
    width: 2px;
    background-color: var(--ui-control-color);
    opacity: var(--icon-opacity);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover {
    &:before,
    &:after {
      background-color: var(--ui-control-hover-color);
    }
  }

  &:active {
    &:before,
    &:after {
      background-color: var(--ui-control-active-color);
    }
  }
}

.datewrapper,
.timewrapper {
  display: inline-block;
  position: relative;

  .text {
    position: relative;
    z-index: 1;
    width: 100%;

    & + div[data-icon] {
      display: none;
    }

    &:placeholder-shown,
    &.empty-value {
      & + div[data-icon] {
        display: block;
        position: absolute;
        top: calc(50% - 12px);
        @include left(14px);
        z-index: 0;
        color: var(--light-text-color);

        &,
        &:before {
          user-select: none;
          pointer-events: none;
          z-index: 1;
        }
      }
    }
  }
}

.datewrapper {
  width: 8em;
}

.timewrapper {
  width: 7em;
}

@include placeholder-styles(var(--gray-400));

/* Kill IE's special text features */
::-ms-reveal,
::-ms-clear {
  display: none;
}

/* Asset indexing related */
tr.indexingSession td {
  height: 34px;
  padding: 1px 10px;
}

tr.indexingSession td.progress div.progressContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

tr.indexingSession td.progress div.progressContainer .progressbar {
  width: 70%;
  position: relative;
  left: 0;
  height: 12px;
  z-index: 1;
}

tr.indexingSession td.progress div.progressContainer div.progressInfo {
  width: 20%;
}

// Selects
.select:not(.selectize),
.select:not(.selectize) select {
  @include select-styles;
}

.select:not(.selectize) {
  @include select-container-styles;
}

.select:not(.selectize):after {
  @include select-arrow-styles;
}

.select:not(.selectize) select {
  @include select-input-styles;
  white-space: pre;
}

.select:not(.selectize).fullwidth select {
  @include select-input-fullwidth-styles;
}

.select:not(.selectize) select {
  &:hover {
    @include select-input-focused-styles;
  }

  @include focus-styles {
    @include select-input-focused-styles;
  }
}

.select:not(.selectize).small:after {
  top: 9px;
}

.select:not(.selectize).small select {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 11px;
}

/* selectize reset */
.selectize-control .selectize-input.disabled {
  opacity: 1;
  cursor: not-allowed !important;

  * {
    cursor: not-allowed !important;
  }
}

.selectize .selectize-input {
  display: block;
  border-color: inherit;
  box-shadow: none;
  background-color: transparent;

  .item {
    max-width: 100%;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.selectize.select .selectize-input:after {
  display: none;
}

body .selectize-dropdown {
  border: none;
  z-index: 101;
}

/* single select styles */
.selectize.select {
  @include placeholder-styles(var(--gray-700));
  height: 34px;

  &:not(.fullwidth) {
    width: 25em;
    max-width: 100%;
  }
}

.datetimewrapper .selectize.select:not(.fullwidth) {
  max-width: calc(100% - 29px);
}

.selectize.select .selectize-control {
  @include select-container-styles;

  &:after {
    @include select-arrow-styles;
  }
}

.selectize.select .selectize-input {
  @include select-styles;

  &.focus {
    box-shadow: var(--focus-ring);
  }
}

.selectize.select .selectize-input {
  @include select-input-styles;
}

.selectize.select .selectize-control,
.selectize.select .selectize-input {
  width: 100%;
}

/* multi select styles */
.selectize.multiselect .selectize-input {
  min-height: 34px;
  padding-bottom: 3px;

  &.focus {
    @include input-focused-styles;
  }

  & > .item {
    @include token-styles;
    display: inline-flex;
    flex-direction: row;
    padding: 3px 7px !important;

    &.active {
      @include active-token-styles;
    }

    & > .remove {
      position: static;
      @include margin(-1px, -3px, 0, 3px);
      border-left: none;
      padding: 0;
      font-size: 0;
      color: var(--ui-control-color);

      &:hover {
        color: var(--ui-control-hover-color);
        background-color: transparent;
      }

      &:before {
        font-size: 14px;
        @include icon;
        content: 'remove';
      }
    }
  }
}

/* menu styles */
body {
  .selectize-dropdown {
    margin-top: 1px;

    [data-selectable],
    .option,
    .optgroup-header {
      @include menu-option-styles;
    }

    .optgroup {
      &:not(:first-child) {
        border-top: 1px solid var(--hairline-color);
      }
      padding-top: 5px;

      .option {
        padding-left: 24px;
      }
    }

    .optgroup-header {
      @include h6-styles;
      margin: 0;
      padding: 4px 0;
    }

    .active:not(.selected),
    .option:hover {
      background-color: var(--gray-100);
    }

    .selected {
      @include menu-option-active-styles;
      cursor: default !important;

      .light {
        color: inherit !important;
      }
    }
  }

  .selectize-dropdown-content {
    @include menu-styles;
    max-height: 70vh;

    .option {
      min-height: 18px;
    }

    & > div[data-value='new'] {
      &:before {
        @include icon;
        content: 'plus';
        margin-right: 5px;
      }

      &:after {
        content: '…';
      }
    }
  }

  &.no-scroll .selectize-dropdown-content {
    max-height: 200px; // default for selectize
  }
}

/* datepicker */
.ui-datepicker {
  position: fixed;
  top: -300px;
  @include margin-left(1px);
  -padding: 10px;
  width: 210px;
  height: 242px;
  z-index: 101 !important;
}

.ui-datepicker-header {
  padding: 8px 8px 4px;
}

.ui-datepicker-prev {
  @include floatleft;
}

.ui-datepicker-next {
  @include floatright;
}

.ui-datepicker-prev span,
.ui-datepicker-next span {
  display: none;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover:after {
    border-color: var(--link-color);
  }
}

.ui-datepicker-prev:after {
  @include angle(left);
}

.ui-datepicker-next:after {
  @include angle(right);
}

.ui-datepicker-title {
  text-align: center;
}

.ui-datepicker-calendar th,
.ui-datepicker-calendar td {
  padding: 2px !important;
}

.ui-datepicker-calendar th span,
.ui-datepicker-calendar td a {
  display: block;
  width: toRem(26);
  line-height: 26px;
  text-align: center;
  color: var(--text-color);
}

.ui-datepicker-calendar th span {
  color: var(--medium-text-color);
  font-weight: normal;
}

.ui-datepicker-calendar td a {
  border-radius: 2px;
}

.ui-datepicker-calendar td a:hover {
  background-color: var(--light-sel-color);
  text-decoration: none;
}

.ui-datepicker-calendar td a.ui-state-active {
  background-color: var(--dark-sel-color);
  color: var(--white);
  @include light-on-dark-text;
  cursor: default;
}

.ui-datepicker-calendar td.ui-datepicker-today a {
  border-radius: 13px;
  box-shadow: inset 0 0 0 2px var(--light-sel-color);
}

/* timepicker */
.ui-timepicker-wrapper {
  z-index: 101;
}

.ui-timepicker-list {
  @include margin-left(1px);
  overflow-y: auto;
  width: calc(14px + 6em);
  height: 210px;
  z-index: 100;
}

.ui-timepicker-list li {
  @include padding(2px, 0, 2px, 14px);
  white-space: nowrap;
  cursor: pointer;
}

.ui-timepicker-list li:hover {
  background-color: var(--light-sel-color);
}

.ui-timepicker-list li.ui-timepicker-selected {
  background-color: var(--dark-sel-color);
  color: var(--white);
  @include light-on-dark-text;
  cursor: default;
}

/* slide picker */
.slide-picker {
  display: flex;
  height: 15px;
  white-space: nowrap;

  a {
    border: 1px solid var(--hairline-color);
    @include border-left(none);
    background-image: none;
    width: 7px;
    height: 13px;
    margin-left: 0;
    display: inline-block;
    margin-top: 1px;
    margin-bottom: 1px;
    box-sizing: border-box;

    &:first-child {
      width: 8px;
      @include border-left(1px solid var(--gray-400) !important);
    }
  }

  &:not(:hover) a.active,
  &:hover a.active-hover {
    border-top-color: var(--gray-400);
    border-bottom-color: var(--gray-400);
    height: 15px;
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      @include border-left(1px solid var(--gray-400));
      @include border-top-left-radius(var(--small-border-radius));
      @include border-bottom-left-radius(var(--small-border-radius));
    }
  }

  &:not(:hover) a.last-active,
  &:hover a.last-active-hover {
    @include border-right(1px solid var(--gray-400));
    @include border-top-right-radius(var(--small-border-radius));
    @include border-bottom-right-radius(var(--small-border-radius));
  }

  &:focus {
    a.active {
      background-color: var(--light-sel-color);
    }
  }
}

/* errors */
ul.errors {
  margin-top: 5px;
  list-style-type: square;
  @include padding-left(20px);
}

ul.errors li {
  color: var(--error-color);
}

/* message pages */
.message-container {
  position: absolute;
  z-index: 100;
  top: 0;
  @include left(0);
  width: 100%;
  height: 100%;

  &.no-access {
    background-color: transparentize($grey900, 0.5);
  }

  .pane {
    top: 50%;
    margin-top: -33px !important;
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    box-shadow: 0 25px 100px transparentize($grey900, 0.5);
  }
}

/* licensing issues page */
body.licensing-issues {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: var(--padding);

  #licensing-issues {
    display: flex;
    flex-direction: row;
    max-width: 50em;

    .buttons {
      justify-content: space-between;
      [data-icon] {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  #continue {
    margin: 0;
  }
}

/* auto-suggest */
.autosuggest-container {
  position: relative;
}

.autosuggest__results-container {
  position: absolute;
  z-index: 2;
  width: 100%;
  border-radius: var(--large-border-radius);
  background-color: var(--white);
  @include shadow;
  box-sizing: border-box;
  padding: 0 14px;
  text-align: left;
  @include sans-serif-font;
  font-size: 1em !important;

  .autosuggest__results-before {
    @include h6-styles;
    margin-top: 14px !important;
  }

  .autosuggest__results-item {
    @include menu-option-styles;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &.autosuggest__results-item--highlighted {
      @include menu-option-active-styles;
      cursor: pointer;

      .light {
        color: $grey100 !important;
      }
    }
  }
}

/* ----------------------------------------
/* Matrix
/* ----------------------------------------*/

.matrix-configurator {
  & > .field {
    max-width: none;

    & > .input {
      display: flex;
      align-items: stretch;
      background-color: var(--gray-050);
      box-shadow: none;
    }
  }

  .mc-sidebar {
    box-sizing: border-box;

    .mc-col-items {
      margin-top: -1px;
      padding-top: 1px;
    }

    &.block-types {
      width: 200px;
      @include border-top-left-radius(var(--small-border-radius));
      @include border-bottom-left-radius(var(--small-border-radius));

      & > .mc-col-inner-container {
        & > .mc-col-heading {
          @include border-top-left-radius(var(--small-border-radius));
        }

        & > .mc-col-items {
          .btn {
            margin: 14px;
          }
        }
      }
    }

    &.mc-fields {
      width: 240px;
      z-index: 1;
      background: #fff;
      box-shadow: -1px 0 0 0 transparentize($grey900, 0.9),
        1px 0 0 0 transparentize($grey900, 0.9);

      .mc-col-items {
        padding: 14px;

        .btn {
          margin-top: 14px;
        }
      }
    }
  }

  .mc-sidebar,
  .mc-field-settings {
    & > .mc-col-inner-container > .mc-col-heading {
      margin: 0;
      padding: 7px 14px 6px;
      border-bottom: 1px solid var(--hairline-color);
      background-color: var(--gray-050);
      background-image: linear-gradient(
        transparentize($grey800, 1),
        transparentize($grey800, 0.95)
      );
    }
  }

  .mc-field-settings {
    flex: 1;
    position: relative;
    @include border-top-right-radius(var(--small-border-radius));
    @include border-bottom-right-radius(var(--small-border-radius));

    & > .mc-col-inner-container {
      & > .mc-col-heading {
        padding-left: 24px;
        padding-right: 24px;
        @include border-top-right-radius(var(--small-border-radius));
      }

      & > .mc-col-items {
        padding: 24px;
      }
    }
  }
}

.matrixconfigitem {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: default;
  min-height: 48px;
  box-sizing: border-box;

  &.mci-blocktype {
    margin-top: -1px;
    padding: 8px 14px;
    border: solid var(--hairline-color);
    border-width: 1px 0;
    background-color: var(--gray-100);

    &.sel {
      z-index: 1;
      background-color: var(--gray-200);
    }
  }

  &.mci-field {
    border-radius: var(--medium-border-radius);
    padding: 7px 10px;
    background-color: var(--gray-100);

    &.sel {
      background-color: var(--gray-200);

      .slide-picker:focus a.active {
        background-color: darken($lightTextColor, 0.5%);
      }
    }

    & + .mci-field {
      margin-top: 7px;
    }
  }

  .mci-name {
    flex: 1;
    overflow: hidden;

    h4,
    .smalltext {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h4 {
      margin-bottom: 2px;
      font-weight: normal;
      color: var(--text-color);

      &.mci-required:after {
        @include icon;
        @include margin(-2px, 0, 0, 4px);
        content: 'asterisk';
        font-size: 8px;
      }
    }
  }

  &.error .mci-name h4 {
    color: var(--error-color);
  }

  .slide-picker,
  .icon {
    @include margin-left(7px);
  }

  .icon {
    display: block;

    &:not(.error) {
      &:before {
        color: var(--ui-control-color);
      }

      &:hover:before {
        color: var(--ui-control-hover-color);
      }

      &:active:before {
        color: var(--ui-control-active-color);
      }
    }

    &.error:before {
      color: var(--error-color);
    }
  }
}

/* Matrix fields */
.matrix {
  & > .buttons {
    margin-top: 10px;
  }
}

$titlebarBorderRadius: calc(var(--large-border-radius) - 1px);

.matrixblock {
  position: relative;
  margin-bottom: 10px;
  padding: 0 var(--m) var(--m);
  border-radius: var(--large-border-radius);
  border: 1px solid var(--hairline-color);
  background-color: var(--gray-050);

  &.static {
    padding-top: 14px;
  }

  .flex-fields {
    --row-gap: var(--m) !important;
  }

  & > .titlebar {
    margin: 0 -14px;
    width: calc(100% + 28px);
    box-sizing: border-box;
    border-radius: $titlebarBorderRadius $titlebarBorderRadius 0 0;
    @include padding(5px, 70px, 5px, 35px);
    color: var(--light-text-color);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
    cursor: default;
    user-select: none;
    position: relative;
    background-color: darken($grey050, 2%);

    &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: -1px;
      left: 14px;
      width: calc(100% - 28px);
      height: 1px;
      background-color: var(--hairline-color);
    }

    & > .blocktype {
      display: inline;
      color: var(--medium-text-color);
    }

    & > .preview {
      @include margin-left(7px);
      display: inline;
      opacity: 0;
      transition: opacity linear 100ms;

      span {
        opacity: 0.5;
      }
    }
  }

  &.disabled-entry {
    & > .titlebar {
      @include padding-right(90px);
    }

    & > .actions {
      & > .status {
        &.off {
          display: block;
        }
      }
    }
  }

  &.collapsed {
    & > .titlebar {
      border-radius: var(--titlebar-border-radius);
      border-bottom: none;

      & > .preview {
        opacity: 1;
      }
    }
  }

  & > .checkbox {
    position: absolute;
    top: 7px;
    @include left(14px);
  }

  & > .actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 3px;
    @include right(14px);
    cursor: default;

    & > * {
      @include margin(0, 0, 0, 5px);
    }

    & .settings {
      padding: 0 8px;
      height: var(--touch-target-size);
      min-height: var(--touch-target-size);
      color: var(--ui-control-color);

      &:before {
        @include margin-right(0 !important);
      }

      &:not(:hover):not(:active) {
        background-color: transparent;
      }
    }

    & > .move {
      margin-top: -3px !important;
    }

    & > .status {
      &.off {
        display: none;
      }
    }

    a:not([data-action]) {
      padding: 0;
      height: 20px;
      text-align: center;
      color: var(--dark-hairline-color);
      transform: color linear 100ms;

      &.settings:after {
        @include margin-left(3px);
        border-color: var(--dark-hairline-color);
        transform: border-color linear 100ms;
      }

      &:hover {
        color: var(--link-color);

        &.settings:after {
          border-color: var(--link-color);
        }
      }
    }
  }

  &:not(.static) {
    & > .fields {
      padding-top: 14px;
    }
  }

  & > .fields > .flex-fields > .field {
    &:before {
      display: none;
    }
  }

  & > .buttons {
    margin-top: 0;
    height: 30px;
  }
}

/* categories */
.add-category-form {
  margin-top: 24px;
}

.add-category-form .texticon {
  width: 200px;
  @include floatleft;
  @include margin-right(5px);
}

.add-category-form .texticon .text {
  @include padding-right(30px);
}

.add-category-form .texticon .spinner {
  position: absolute;
  top: 0;
  @include right(5px);
}

/* site pickers */
body.sitepicker {
  #main-content {
    padding: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  #content-container {
    max-width: 400px;
  }
}

.sitepicker-group {
  li {
    &:not(:first-child) {
      a {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        &:not(:hover) {
          border-top-color: transparent;
        }
      }
    }

    &:not(:last-child) {
      a {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    a {
      display: block;
      position: relative;
      text-align: left;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid var(--hairline-color);
      border-radius: var(--medium-border-radius);
      @include padding(9px, 42px, 9px, 15px);
      font-size: 16px;
      line-height: 1.4;

      &:after {
        font-size: 14px;
        position: absolute;
        top: calc(50% - 7px);
        @include right(12px);
        margin: 0;
        padding: 0;
      }

      &:hover {
        border-color: var(--link-color);
        text-decoration: none;
        z-index: 1;
      }
    }
  }
}

/* ----------------------------------------
/* IE hacks
/* ----------------------------------------*/

/* Fix layout of modal element selectors for IE8 */
.elementselectormodal .body .main {
  float: left \9;
  width: 445px \9;
}

/* ----------------------------------------
/*  Retina graphics
/* ----------------------------------------*/

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 1.5dppx) {
  .structure ul li {
    background-size: 40px;
    body.ltr & {
      background-image: url(../images/branch_2x.png);
    }
    body.rtl & {
      background-image: url(../images/branch_rtl_2x.png);
    }
  }

  .hud .tip-left {
    background-image: url(../images/hudtip_left_2x.png);
    background-size: 15px 30px;
  }
  .hud .tip-top {
    background-image: url(../images/hudtip_top_2x.png);
    background-size: 30px 15px;
  }
  .hud .tip-right {
    background-image: url(../images/hudtip_right_2x.png);
    background-size: 15px 30px;
  }
  .hud .tip-bottom {
    background-image: url(../images/hudtip_bottom_2x.png);
    background-size: 30px 15px;
  }
  .hud.has-footer .tip-bottom {
    background-image: url(../images/hudtip_bottom_gray_2x.png);
  }
}
