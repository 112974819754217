@charset "UTF-8";
@import '@craftcms/sass/mixins';

:root {
  --white: #fff;
  --black: #000;

  --gray-050-hsl: 212, 60%, 97%;
  --gray-100-hsl: 212, 50%, 93%;
  --gray-200-hsl: 212, 30%, 85%;
  --gray-300-hsl: 211, 13%, 65%;
  --gray-350-hsl: 211, 11%, 59%;
  --gray-400-hsl: 210, 10%, 53%;
  --gray-500-hsl: 211, 12%, 43%;
  --gray-550-hsl: 210, 13%, 40%;
  --gray-600-hsl: 209, 14%, 37%;
  --gray-700-hsl: 209, 18%, 30%;
  --gray-800-hsl: 209, 20%, 25%;
  --gray-900-hsl: 210, 24%, 16%;
  --gray-1000-hsl: 210, 24%, 10%;

  --gray-050: hsl(var(--gray-050-hsl));
  --gray-100: hsl(var(--gray-100-hsl));
  --gray-200: hsl(var(--gray-200-hsl));
  --gray-300: hsl(var(--gray-300-hsl));
  --gray-350: hsl(var(--gray-350-hsl));
  --gray-400: hsl(var(--gray-400-hsl));
  --gray-500: hsl(var(--gray-500-hsl));
  --gray-550: hsl(var(--gray-550-hsl));
  --gray-600: hsl(var(--gray-600-hsl));
  --gray-700: hsl(var(--gray-700-hsl));
  --gray-800: hsl(var(--gray-800-hsl));
  --gray-900: hsl(var(--gray-900-hsl));
  --gray-1000: hsl(var(--gray-1000-hsl));

  --red-050: #ffe3e3;
  --red-100: #ffbdbd;
  --red-200: #ff9b9b;
  --red-300: #f86a6a;
  --red-400: #ef4e4e;
  --red-500: #e12d39;
  --red-600: #cf1124;
  --red-700: #ab091e;
  --red-800: #8a041a;
  --red-900: #610316;

  --orange-050: #fff7ed;
  --orange-100: #ffedd5;
  --orange-200: #fed7aa;
  --orange-300: #fdba74;
  --orange-400: #fb923c;
  --orange-500: #f97316;
  --orange-600: #ea580c;
  --orange-700: #c2410c;
  --orange-800: #9a3412;
  --orange-900: #7c2d12;
  --orange-950: #431407;

  --amber-050: #fffbeb;
  --amber-100: #fef3c7;
  --amber-200: #fde68a;
  --amber-300: #fcd34d;
  --amber-400: #fbbf24;
  --amber-500: #f59e0b;
  --amber-600: #d97706;
  --amber-700: #b45309;
  --amber-800: #92400e;
  --amber-900: #78350f;
  --amber-950: #451a03;

  --yellow-050: #fffbea;
  --yellow-100: #fff3c4;
  --yellow-200: #fce588;
  --yellow-300: #fadb5f;
  --yellow-400: #f7c948;
  --yellow-500: #f0b429;
  --yellow-600: #de911d;
  --yellow-700: #cb6e17;
  --yellow-750: #bd5a14;
  --yellow-800: #b44d12;
  --yellow-900: #8d2b0b;

  --lime-050: #f7fee7;
  --lime-100: #ecfccb;
  --lime-200: #d9f99d;
  --lime-300: #bef264;
  --lime-400: #a3e635;
  --lime-500: #84cc16;
  --lime-600: #65a30d;
  --lime-700: #4d7c0f;
  --lime-800: #3f6212;
  --lime-900: #365314;
  --lime-950: #1a2e05;

  --green-050: #f0fdf4;
  --green-100: #dcfce7;
  --green-200: #bbf7d0;
  --green-300: #86efac;
  --green-400: #4ade80;
  --green-500: #22c55e;
  --green-600: #16a34a;
  --green-700: #15803d;
  --green-800: #166534;
  --green-900: #14532d;
  --green-950: #052e16;

  --emerald-050: #ecfdf5;
  --emerald-100: #d1fae5;
  --emerald-200: #a7f3d0;
  --emerald-300: #6ee7b7;
  --emerald-400: #34d399;
  --emerald-500: #10b981;
  --emerald-600: #059669;
  --emerald-700: #047857;
  --emerald-800: #065f46;
  --emerald-900: #064e3b;
  --emerald-950: #022c22;

  --teal-050: #effcf6;
  --teal-100: #c6f7e2;
  --teal-200: #8eedc7;
  --teal-300: #65d6ad;
  --teal-400: #3ebd93;
  --teal-500: #27ab83;
  --teal-550: #20a07b;
  --teal-600: #199473;
  --teal-700: #147d64;
  --teal-800: #0c6b58;
  --teal-900: #014d40;

  --cyan-050: #e0fcff;
  --cyan-100: #bef8fd;
  --cyan-200: #87eaf2;
  --cyan-300: #54d1db;
  --cyan-400: #38bec9;
  --cyan-500: #2cb1bc;
  --cyan-600: #14919b;
  --cyan-700: #0e7c86;
  --cyan-800: #0a6c74;
  --cyan-900: #044e54;

  --sky-050: #f0f9ff;
  --sky-100: #e0f2fe;
  --sky-200: #bae6fd;
  --sky-300: #7dd3fc;
  --sky-400: #38bdf8;
  --sky-500: #0ea5e9;
  --sky-600: #0284c7;
  --sky-700: #0369a1;
  --sky-800: #075985;
  --sky-900: #0c4a6e;
  --sky-950: #082f49;

  --blue-050: #e3f8ff;
  --blue-100: #b3ecff;
  --blue-200: #81defd;
  --blue-300: #5ed0fa;
  --blue-400: #40c3f7;
  --blue-500: #2bb0ed;
  --blue-600: #1992d4;
  --blue-700: #127fbf;
  --blue-800: #0b69a3;
  --blue-900: #035388;

  --indigo-050: #eef2ff;
  --indigo-100: #e0e7ff;
  --indigo-200: #c7d2fe;
  --indigo-300: #a5b4fc;
  --indigo-400: #818cf8;
  --indigo-500: #6366f1;
  --indigo-600: #4f46e5;
  --indigo-700: #4338ca;
  --indigo-800: #3730a3;
  --indigo-900: #312e81;
  --indigo-950: #1e1b4b;

  --violet-050: #f5f3ff;
  --violet-100: #ede9fe;
  --violet-200: #ddd6fe;
  --violet-300: #c4b5fd;
  --violet-400: #a78bfa;
  --violet-500: #8b5cf6;
  --violet-600: #7c3aed;
  --violet-700: #6d28d9;
  --violet-800: #5b21b6;
  --violet-900: #4c1d95;
  --violet-950: #2e1065;

  --purple-050: #faf5ff;
  --purple-100: #f3e8ff;
  --purple-200: #e9d5ff;
  --purple-300: #d8b4fe;
  --purple-400: #c084fc;
  --purple-500: #a855f7;
  --purple-600: #9333ea;
  --purple-700: #7e22ce;
  --purple-800: #6b21a8;
  --purple-900: #581c87;
  --purple-950: #3b0764;

  --fuchsia-050: #fdf4ff;
  --fuchsia-100: #fae8ff;
  --fuchsia-200: #f5d0fe;
  --fuchsia-300: #f0abfc;
  --fuchsia-400: #e879f9;
  --fuchsia-500: #d946ef;
  --fuchsia-600: #c026d3;
  --fuchsia-700: #a21caf;
  --fuchsia-800: #86198f;
  --fuchsia-900: #701a75;
  --fuchsia-950: #4a044e;

  --pink-050: #ffe3ec;
  --pink-100: #ffb8d2;
  --pink-200: #ff8cba;
  --pink-300: #f364a2;
  --pink-400: #e8368f;
  --pink-500: #da127d;
  --pink-600: #bc0a6f;
  --pink-700: #a30664;
  --pink-800: #870557;
  --pink-900: #620042;

  --rose-050: #fff1f2;
  --rose-100: #ffe4e6;
  --rose-200: #fecdd3;
  --rose-300: #fda4af;
  --rose-400: #fb7185;
  --rose-500: #f43f5e;
  --rose-600: #e11d48;
  --rose-700: #be123c;
  --rose-800: #9f1239;
  --rose-900: #881337;
  --rose-950: #4c0519;
}
