@charset "UTF-8";
@import '@craftcms/sass/mixins';

:root {
  --bg-color: $grey100;
  --bg-color-hsl: #{hue($grey100)}, #{saturation($grey100)},
    #{lightness($grey100)};

  // submit button colors
  --primary-color: var(--red-500);
  --secondary-color: var(--gray-500);

  --input-color: hsl(212, 25%, 50%);

  // text colors
  --text-color: var(--gray-700);
  --medium-dark-text-color: var(--gray-550);
  --medium-text-color: var(--gray-550);
  --light-text-color: var(--gray-500);
  --link-color: #1f5fea;

  // font styles
  --font-weight-bold: 700;

  // menu colors
  --menu-option-color: var(--text-color);
  --menu-option-active-color: var(--white);
  --menu-option-active-background-color: var(--dark-sel-color);

  // hairline colors
  --hairline-color: #{transparentize(hsl(209, 20%, 25%), 0.9)};
  --medium-hairline-color: #{transparentize(hsl(209, 14%, 37%), 0.75)};
  --dark-hairline-color: #{transparentize(hsl(210, 10%, 53%), 0.5)};

  // focus colors
  --light-focus-color: var(--blue-300);
  --medium-focus-color: var(--blue-500);
  --dark-focus-color: #0f74b1;

  // focus rings
  --light-focus-ring: 0 0 0 1px #5ed0fa,
    0 0 0 3px #{transparentize(#5ed0fa, 0.3)};
  --medium-focus-ring: 0 0 0 1px #2bb0ed,
    0 0 0 3px #{transparentize(#2bb0ed, 0.3)};
  --dark-focus-ring: 0 0 0 1px #0f74b1,
    0 0 0 3px #{transparentize(#0f74b1, 0.3)};

  // selection colors
  --light-sel-color: var(--gray-200);
  --dark-sel-color: var(--gray-500);

  // alert/notice colors
  --error-color: #d81e23;
  --warning-color: var(--yellow-800);
  --success-color: var(--teal-700);
  --notice-color: var(--blue-800);

  // status colors
  --enabled-color: var(--teal-550);
  --pending-color: var(--yellow-700);
  --disabled-color: var(--red-600);

  // misc colors
  --indicator-border-color: var(--yellow-700);
  --indicator-icon-color: var(--yellow-750);

  // UI element styles
  --small-border-radius: 3px;
  --medium-border-radius: 4px;
  --large-border-radius: 5px;

  --menu-border-radius: var(--medium-border-radius);
  --checkbox-size: 16px;
  --radio-size: 16px;
}
